import Copy from '@/components/Copy.tsx'
import PageLayout from '@/components/PageLayout.tsx'
import MainMotion from '@/components/anim/MainMotion.tsx'
import { useFullTokens, useTokenStore } from '@mosaicag/swap-widget'

import { LiquidityTabs } from '@/pages/liquidity/my-positions/MyPositions.tsx'
import { Icon } from '@iconify/react'
import { Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react'
import { ReactElement, useMemo } from 'react'
import { TokenImage } from '../../../components/TokenImage.tsx'
import { Body4, Body5, Subtitle3 } from '../../../components/Typography.tsx'
import { useGetLiqPools, useNavigateToPool } from '../../../hooks/liquidity/useGetLiqPools.ts'

export default function Explore() {
  const { getLiqPools: poolList, isValidating } = useGetLiqPools(1, 10)

  const { followingTokenData } = useTokenStore()
  const { navigateToAddLiq } = useNavigateToPool()
  const { data: fullTokenData } = useFullTokens()

  const tableRowRender = useMemo(() => {
    if (isValidating || !poolList) {
      return []
    }

    const rows: ReactElement[] = []

    poolList.result?.forEach((pool, index) => {
      const token0Info = followingTokenData[pool.tokenX] || fullTokenData?.[pool.tokenX]
      const token1Info = followingTokenData[pool.tokenY] || fullTokenData?.[pool.tokenY]
      rows.push(
        <TableRow key={index} className="cursor-pointer">
          <TableCell>
            <div className="flex flex-col gap-1.5">
              <div className="flex items-center gap-1">
                <div className="flex">
                  <TokenImage
                    width={20}
                    height={20}
                    className="min-h-[20px] min-w-[20px]"
                    logoUrl={token0Info?.logoUrl}
                  />
                  <TokenImage
                    width={20}
                    height={20}
                    className="-ml-2 min-h-[20px] min-w-[20px]"
                    logoUrl={token1Info?.logoUrl}
                  />
                </div>
                <Subtitle3 className="mr-1.5 flex items-center gap-1 whitespace-nowrap text-white">
                  {token0Info?.symbol ?? '--'}/{token1Info?.symbol ?? '--'} <Copy value={pool.pool_addr} />
                </Subtitle3>
              </div>
              <div className="flex justify-between">
                {pool.isStable ? (
                  <Body5 className="text-baseGrey">
                    <div className="flex items-center gap-1">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M9.50003 8.50001H2.50003M8.50003 6.50001H3.50003M7.50003 4.50001H4.50003M5.93303 1.63401L1.55453 10.3915C1.54882 10.4029 1.54613 10.4156 1.54671 10.4284C1.54728 10.4411 1.55111 10.4535 1.55782 10.4644C1.56453 10.4753 1.5739 10.4843 1.58505 10.4905C1.5962 10.4967 1.60876 10.5 1.62153 10.5H10.3785C10.3913 10.5 10.4039 10.4967 10.415 10.4905C10.4262 10.4843 10.4355 10.4753 10.4422 10.4644C10.449 10.4535 10.4528 10.4411 10.4534 10.4284C10.4539 10.4156 10.4512 10.4029 10.4455 10.3915L6.06753 1.63401C6.06128 1.62159 6.05171 1.61116 6.03988 1.60387C6.02805 1.59657 6.01443 1.59271 6.00053 1.59271C5.98663 1.59271 5.97301 1.59657 5.96118 1.60387C5.94935 1.61116 5.93927 1.62159 5.93303 1.63401Z"
                          stroke="#8B8D91"
                          strokeLinecap="round"
                        />
                      </svg>
                      Stable
                    </div>
                  </Body5>
                ) : (
                  <Body5 className="text-baseGrey">
                    <div className="flex items-center gap-1">
                      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M8 5.89L10.12 2.225L10.985 2.725L8.37 7.25L5.115 5.375L2.73 9.5H11V10.5H1V1.5H2V8.77L4.75 4L8 5.89Z"
                          fill="#8B8D91"
                        />
                      </svg>
                      Volatile
                    </div>
                  </Body5>
                )}
                <Body5 className="text-baseGrey">{Number(pool.fee) / 10000}%</Body5>
              </div>
            </div>
          </TableCell>
          <TableCell>
            <Body4 className="text-white">0</Body4>
          </TableCell>
          <TableCell>
            <Body4 className="text-white">0</Body4>
          </TableCell>
          <TableCell>
            <Body4 className="text-white">0</Body4>
          </TableCell>
          <TableCell>
            <Icon
              icon="mage:plus-circle-fill"
              className="text-primary"
              fontSize={18}
              onClick={() => navigateToAddLiq({ token0: token0Info, token1: token1Info, pool: pool.pool_addr })}
            />
          </TableCell>
        </TableRow>,
      )
    })

    return rows
  }, [followingTokenData, isValidating, navigateToAddLiq, poolList, fullTokenData])

  return (
    <PageLayout>
      <LiquidityTabs />

      <MainMotion>
        <Table
          aria-label="explore-table"
          classNames={{
            base: ['gap-0'],
            wrapper: ['bg-baseGrey1', 'p-0', 'gap-0', 'rounded-xl', 'border-[0.5px] border-borderGrey'],
            th: ['bg-black2', 'px-5 py-2', 'font-medium'],
            td: ['px-5 py-2', 'border-t-[0.5px] border-[#433B28]'],
          }}
        >
          <TableHeader>
            <TableColumn>&nbsp;</TableColumn>
            <TableColumn>TVL</TableColumn>
            <TableColumn>24h Vol</TableColumn>
            <TableColumn>APR</TableColumn>
            <TableColumn>&nbsp;</TableColumn>
          </TableHeader>
          <TableBody
            emptyContent={'No liquidity pools'}
            isLoading={isValidating}
            loadingContent={<Spinner label="Loading..." />}
          >
            {tableRowRender.map((row) => row)}
          </TableBody>
        </Table>
      </MainMotion>
    </PageLayout>
  )
}
