import { MosaicTextIcon } from '@/components/Icons.tsx'
import { IS_SUPPORT_POOL } from '@/constants/limitOrder.ts'
import { Button, Image } from '@nextui-org/react'
import { getWalletImagePath } from '../constants/wallet.ts'
import { useIsMd, useIsSm } from '../hooks/useMedia.ts'
import useMovementWallet from '../hooks/useMovementWallet.ts'
import { useModal } from '../provider/ModalProvider.tsx'
import { ButtonBase } from './Button.tsx'
import { Menu, MenuMobile } from './Menu.tsx'
import { MODAL_LIST } from './modals/constant.ts'
import { Body3 } from './Typography.tsx'

function ButtonConnectWallet() {
  const { onOpenModal } = useModal()

  const { connected, account, name } = useMovementWallet()
  const isSm = useIsSm()

  const onPress = () => {
    if (connected) {
      onOpenModal(MODAL_LIST.ACTIVITIES)
    } else {
      onOpenModal(MODAL_LIST.CONNECT_WALLET)
    }
  }

  return (
    <ButtonBase
      v="primary"
      className={
        'flex h-[40px] sm:h-[30px] min-w-[140px] px-2 sm:min-w-[100px] items-center gap-2 rounded-[8px]' +
        ' ' +
        (connected
          ? 'border-1 border-borderGrey bg-transparent data-[hover]:bg-transparent'
          : 'border-1 border-primary bg-primary')
      }
      onPress={onPress}
    >
      {connected && name && <Image src={getWalletImagePath(name)} width={18} />}
      <Body3 className={connected ? 'text-baseGrey' : 'text-baseBlack'}>
        {account ? `${account.address.slice(0, 6)}...${account.address.slice(-4)}` : `Connect${!isSm ? ' Wallet' : ''}`}
      </Body3>
    </ButtonBase>
  )
}

export default function Header() {
  const isMd = useIsMd()
  const goHome = () => {
    window.location.href = '/'
  }

  return (
    <header className="z-[1] mx-auto mt-[20px] flex w-full max-w-[1140px] items-center justify-between px-8 md:px-4">
      <div className="flex items-center gap-6">
        <Button
          variant="light"
          className="h-fit min-h-fit w-fit min-w-fit justify-start rounded-none data-[hover]:bg-transparent"
          disableRipple
          isIconOnly
          disableAnimation
          onPress={goHome}
        >
          {/* <img src="/icons/logo2025.svg" width={isMd ? 112 : 144} className="sm:min-h-[28px]" /> */}
          <MosaicTextIcon size={isMd ? 28 : 36} className="sm:min-h-[28px]" />
        </Button>
        <Menu />
      </div>
      <div className="flex items-center gap-3 ">
        <ButtonBase
          isDisabled
          v="primary"
          style={{
            opacity: 1,
          }}
          className={
            'md:hidden flex h-[40px] min-h-[40px] min-w-[140px] items-center gap-2 rounded-[8px] p-0' +
            ' border-1 border-borderGrey bg-transparent hover:cursor-default data-[hover]:bg-transparent'
          }
        >
          <Image
            src="https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/MOVE.png"
            width={18}
          />
          <Body3 className={'text-baseGrey'}>Porto testnet</Body3>
        </ButtonBase>
        <ButtonConnectWallet />
        {IS_SUPPORT_POOL && <MenuMobile />}
      </div>
    </header>
  )
}
