import { useTokenInOutInfo } from '@/hooks/limit/useTokenInOutInfo.ts'
import { useNavigateToPool } from '@/hooks/liquidity/useGetLiqPools.ts'
import { GetUserActivePositionResponseData } from '@/hooks/liquidity/useGetUserActivePosition.ts'
import { Button, Skeleton } from '@nextui-org/react'
import { Body2, Body4, Subtitle3 } from '../../../components/Typography.tsx'
import { useGetUserPosition } from '../../../hooks/contracts/liquidity/useGetUserPosition.ts'
import { numberWithCommas } from '../../../utils/number.ts'

export function PositionItem({ data: { pool } }: { data: GetUserActivePositionResponseData }) {
  const { tokenX, tokenY, poolAddr } = pool

  const {
    tokenInInfo: token0Info,
    tokenOutInfo: token1Info,
    tokenInDecimals: token0Decimals,
    tokenOutDecimals: token1Decimals,
  } = useTokenInOutInfo(tokenX, tokenY)

  const {
    userLiq,
    pooledToken0,
    pooledToken1,
    poolShare,
    isValidating: isValidatingQuote,
  } = useGetUserPosition(poolAddr, token0Decimals, token1Decimals)

  const { navigateToAddLiq, navigateToRemoveLiq } = useNavigateToPool()

  return (
    <div className="flex flex-col gap-1.5 pb-3">
      <div className="mb-1.5 flex flex-col gap-2">
        <Subtitle3 className="text-white">Your position</Subtitle3>
        <div className="flex items-center justify-between gap-3">
          <Body4 className="text-baseGrey">Your total pool tokens</Body4>
          {isValidatingQuote ? (
            <div className="flex h-[14.04px] w-[50px] items-center">
              <Skeleton className="h-[14.04px] w-full rounded bg-baseGrey" />
            </div>
          ) : (
            <Body4 className="text-white">{userLiq ? numberWithCommas(userLiq.toString()) : '--'}</Body4>
          )}
        </div>
        <div className="flex items-center justify-between gap-3">
          <Body4 className="text-baseGrey">Pooled {token0Info?.symbol}</Body4>
          {isValidatingQuote ? (
            <div className="flex h-[14.04px] w-[50px] items-center">
              <Skeleton className="h-[14.04px] w-full rounded bg-baseGrey" />
            </div>
          ) : (
            <Body4 className="text-white">
              {pooledToken0?.toSignificant(6) ? numberWithCommas(pooledToken0?.toSignificant(6)) : '--'}
            </Body4>
          )}
        </div>
        <div className="flex items-center justify-between gap-3">
          <Body4 className="text-baseGrey">Pooled {token1Info?.symbol}</Body4>
          {isValidatingQuote ? (
            <div className="flex h-[14.04px] w-[50px] items-center">
              <Skeleton className="h-[14.04px] w-full rounded bg-baseGrey" />
            </div>
          ) : (
            <Body4 className="text-white">
              {pooledToken1?.toSignificant(6) ? numberWithCommas(pooledToken1?.toSignificant(6)) : '--'}
            </Body4>
          )}
        </div>
        <div className="flex items-center justify-between gap-3">
          <Body4 className="text-baseGrey">Your pool share</Body4>
          {isValidatingQuote ? (
            <div className="flex h-[14.04px] w-[50px] items-center">
              <Skeleton className="h-[14.04px] w-full rounded bg-baseGrey" />
            </div>
          ) : (
            <Body4 className="text-white">
              {poolShare ? numberWithCommas(poolShare.toString(), false, 2) + '%' : '--'}
            </Body4>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        <Button
          className="rounded-lg bg-primary text-baseGrey1"
          onClick={() => {
            navigateToAddLiq({ token0: token0Info, token1: token1Info, pool: poolAddr })
          }}
        >
          <Body2>Add</Body2>
        </Button>
        <Button
          className="rounded-lg bg-primary text-baseGrey1"
          onClick={() => {
            navigateToRemoveLiq({ token0: token0Info, token1: token1Info, pool: poolAddr })
          }}
        >
          <Body2>Remove</Body2>
        </Button>
      </div>
    </div>
  )
}
