import { AptosWalletContextState, useAptosWallet as abc } from '@razorlabs/wallet-kit'
import { useMemo } from 'react'

export default function useMovementWallet(): AptosWalletContextState {
  const aptosWallet = abc()

  return useMemo(() => {
    const connected = Boolean(aptosWallet.account?.address && aptosWallet.connected)
    return {
      ...aptosWallet,
      connected,
      account: connected ? aptosWallet.account : undefined,
    }
  }, [aptosWallet])
}
