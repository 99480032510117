import { MODAL_LIST } from '@/components/modals/constant.ts'
import { Asset } from '@/constants/asset.ts'
import { AGGREGATOR_API_KEY } from '@/constants/index.ts'
import APP_PATHS from '@/constants/path.ts'
import useSwap from '@/hooks/useSwap.tsx'
import { useModal } from '@/provider/ModalProvider.tsx'
import { useAppDispatch } from '@/redux/hooks/index.ts'
import Widget, { useFullTokens, useTokenStore } from '@mosaicag/swap-widget'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import useMovementWallet from '../../hooks/useMovementWallet.ts'

const getIdFromUrl = (pathname: string) => {
  const pair = pathname.replace(`${APP_PATHS.SWAP}/`, '')
  const tokenInSymbolOrAddress = pair.split('-')[0]
  const tokenOutSymbolOrAddress = pair.split('-')[1]
  return { tokenInSymbolOrAddress, tokenOutSymbolOrAddress }
}

const getSlug = (token: Asset | undefined) => (token?.whitelisted ? token?.symbol : token?.id) ?? ''

const useFindTokenFromUrl = () => {
  const { followingTokenData } = useTokenStore()

  const location = useLocation()

  const { data: fullTokenData } = useFullTokens()
  const findTokensFromUrl = useCallback(() => {
    const followingTokenDataList = Object.values(followingTokenData) as Asset[]
    const fullTokenDataList = Object.values(fullTokenData || {})
    const { tokenInSymbolOrAddress, tokenOutSymbolOrAddress } = getIdFromUrl(location.pathname)
    if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress || !fullTokenDataList.length)
      return { newTokenIn: undefined, newTokenOut: undefined }

    const findFn = (symbolOrAddress: string) =>
      followingTokenDataList.find((token) => token?.id === symbolOrAddress) ||
      fullTokenDataList.find((token) => token?.id === symbolOrAddress) ||
      followingTokenDataList.find((token) => token?.coinType === symbolOrAddress) ||
      fullTokenDataList.find((token) => token?.coinType === symbolOrAddress) ||
      followingTokenDataList.find((token) => token?.symbol === symbolOrAddress) ||
      fullTokenDataList.find((token) => token?.symbol === symbolOrAddress)

    const newTokenIn = findFn(tokenInSymbolOrAddress)
    const newTokenOut = findFn(tokenOutSymbolOrAddress)
    return { newTokenIn, newTokenOut }
  }, [followingTokenData, fullTokenData, location.pathname])

  return findTokensFromUrl
}

export default function SwapPage() {
  const wallet = useMovementWallet()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const location = useLocation()
  const { data: fullTokens } = useFullTokens()
  const { onOpenModal } = useModal()

  const [tokenIn, setTokenIn] = useState<Asset>()
  const [tokenOut, setTokenOut] = useState<Asset>()

  const syncPairToUrl = useCallback(
    (symbolOrAddressIn: string, symbolOrAddressInOut: string) => {
      const { tokenInSymbolOrAddress, tokenOutSymbolOrAddress } = getIdFromUrl(location.pathname)
      const tokenIn = symbolOrAddressIn || tokenInSymbolOrAddress
      const tokenOut = symbolOrAddressInOut || tokenOutSymbolOrAddress

      if (!tokenIn || !tokenOut) {
        navigate(`${APP_PATHS.SWAP}/MOVE-USDC?${params.toString()}`, { replace: true })
        return
      }
      navigate(`${APP_PATHS.SWAP}/${tokenIn}-${tokenOut}?${params.toString()}`, { replace: true })
    },
    [navigate, params, location.pathname],
  )
  const onChangeTokenIn = useCallback(
    (token: Asset) => {
      syncPairToUrl(getSlug(token), '')
      setTokenIn(token)
    },
    [syncPairToUrl],
  )
  const onChangeTokenOut = useCallback(
    (token: Asset) => {
      syncPairToUrl('', getSlug(token))
      setTokenOut(token)
    },
    [syncPairToUrl],
  )
  const onSwitchToken = useCallback(
    (newTokenIn: Asset, newTokenOut: Asset) => {
      syncPairToUrl(getSlug(newTokenIn), getSlug(newTokenOut))
      setTokenIn(newTokenIn)
      setTokenOut(newTokenOut)
    },
    [syncPairToUrl],
  )

  const init = useRef(false)
  const findTokensFromUrl = useFindTokenFromUrl()
  useEffect(() => {
    try {
      if (!Object.values(fullTokens ?? {}).length || init.current) return
      const { newTokenIn, newTokenOut } = findTokensFromUrl()
      if (!newTokenIn) throw new Error(`cannot find tokenIn  `)
      if (!newTokenOut) throw new Error(`cannot find tokenOut `)
      setTokenIn(newTokenIn)
      setTokenOut(newTokenOut)
      // init.current = true
    } catch (err) {
      navigate(`/swap/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [dispatch, findTokensFromUrl, navigate, params, fullTokens])

  const { onSwap } = useSwap()

  return (
    <div className="z-[1] w-full p-4 pt-[40px]">
      <Widget
        {...{
          wallet,
          onChangeTokenIn,
          onChangeTokenOut,
          onSwitchToken,
          tokenIn,
          tokenOut,
          useLocalState: false,
          notification: false,
          defaultInputAmount: params.get('amount') || '1',
          apiKey: AGGREGATOR_API_KEY,
          poweredBy: false,
          onConnectButtonClick: () => onOpenModal(MODAL_LIST.CONNECT_WALLET),
          onTxSuccess: (data) => {
            onSwap({ success: true, ...data })
          },
          onTxFail: (data) => {
            onSwap({ success: false, ...data, hash: '' })
          },
          style: {
            selectTokenModal: {
              border: 'none',
            },
            settings: {
              border: 'none',
            },
            wrapper: {
              border: 'none',
              padding: 0,
              borderRadius: 0,
            },
          },
        }}
      />
    </div>
  )
}
