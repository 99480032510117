export interface Asset {
  decimals: number
  name: string
  symbol: string
  coinType?: string
  faAddress: string
  type?: 'legacy' // custom from fe
  id: string
  whitelisted?: boolean
  logoUrl?: string
}

export const MOVE: Asset = {
  id: '0xa',
  faAddress: '0xa',
  coinType: '0x1::aptos_coin::AptosCoin',

  symbol: 'MOVE',
  name: 'Movement Coin',
  decimals: 8,
  whitelisted: true,
  logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/MOVE.png',
}

export const USDC: Asset = {
  id: '0x1e74c3312b1a7a08eb7cf61310787597ea6609d6d99ce86c0e48399144ea4ce9',
  faAddress: '0x1e74c3312b1a7a08eb7cf61310787597ea6609d6d99ce86c0e48399144ea4ce9',
  coinType: '0x275f508689de8756169d1ee02d889c777de1cebda3a7bbcce63ba8a27c563c6f::tokens::USDC',

  symbol: 'USDC',
  name: 'USD Coin',
  decimals: 6,
  whitelisted: true,
  logoUrl: 'https://raw.githubusercontent.com/razorlabsorg/chainlist/main/chain/aptos/asset/USDC.png',
}
