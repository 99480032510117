import { useCallback, useRef } from 'react'
export function useResetTimerFn() {
  const resetTimerFunction = useRef(() => {})
  const setResetTimerFunc = useCallback((f: () => void) => (resetTimerFunction.current = f), [])

  return {
    resetTimerFn: resetTimerFunction.current,
    setResetTimerFn: setResetTimerFunc,
  }
}
