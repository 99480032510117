import { FaucetIcon } from '@/components/Icons'
import BaseModal from '@/components/modals/BaseModal'
import { IS_SUPPORT_POOL } from '@/constants/limitOrder.ts'
import useIframe from '@/hooks/useIframe'
import { Icon } from '@iconify/react'
import { useDisclosure } from '@nextui-org/react'
import { useLocation, useNavigate } from 'react-router-dom'

const menuItems = IS_SUPPORT_POOL
  ? [
      { name: 'Swap', path: '/swap/MOVE-USDC', icon: 'mdi:swap-horizontal', contain: 'swap' },
      { name: 'Liquidity', path: '/liquidity/my-positions', icon: 'mdi:water', contain: 'liquidity' },
      {
        name: 'Faucet',
        path: '/liquidity/my-positions',
        onClick: () => window.open('https://mizu.testnet.porto.movementnetwork.xyz/'),
        contain: 'faucet',
        icon: <FaucetIcon size={20} />,
      },
    ]
  : [
      {
        name: 'Faucet',
        path: '/liquidity/my-positions',
        onClick: () => window.open('https://mizu.testnet.porto.movementnetwork.xyz/'),
        contain: 'faucet',
        icon: <FaucetIcon size={20} />,
      },
    ]

export const MenuMobile = () => {
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Icon className="hidden sm:block text-baseGrey" icon="mdi:menu" fontSize={28} onClick={onOpen} />
      <BaseModal isOpen={isOpen} onClose={onClose} title="Menu" className="hidden sm:block">
        <div className="flex flex-col px-4 gap-2">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={
                'flex h-fit items-center cursor-pointer w-fit min-w-fit text-[14px] justify-start gap-1 py-1.5 hover:text-primary ' +
                (location.pathname.includes(item.contain) ? '  text-primary' : 'text-baseGrey')
              }
              onClick={() => {
                item.onClick ? item.onClick() : navigate(item.path)
                onClose()
              }}
            >
              {typeof item.icon === 'string' ? <Icon icon={item.icon} width={20} /> : item.icon}
              {item.name}
            </div>
          ))}
        </div>
      </BaseModal>
    </>
  )
}

export function Menu() {
  const location = useLocation()
  const navigate = useNavigate()
  const isIframe = useIframe()

  if (isIframe) return null

  return (
    <div className="flex items-center gap-6 sm:hidden">
      {menuItems.map((item, index) => (
        <div
          key={index}
          className={
            'link-hover h-fit items-center cursor-pointer w-fit min-w-fit text-[14px] justify-start gap-1 py-1.5 hover:text-primary ' +
            (location.pathname.includes(item.contain) ? '  text-primary' : 'text-baseGrey')
          }
          onClick={() => {
            item.onClick ? item.onClick() : navigate(item.path)
          }}
        >
          {typeof item.icon === 'string' ? <Icon icon={item.icon} width={20} /> : item.icon}
          {item.name}
        </div>
      ))}
    </div>
  )
}
