import BaseModal from '@/components/modals/BaseModal.tsx'
import { Button, Image, Spacer } from '@nextui-org/react'
import { IDefaultWallet } from '@razorlabs/wallet-kit'
import { bitget, getWalletImagePath, nightly, okx, razor } from '../../constants/wallet.ts'
import useMovementWallet from '../../hooks/useMovementWallet.ts'
import { useModal } from '../../provider/ModalProvider.tsx'
import { Body4, Subtitle2 } from '../Typography.tsx'
import { MODAL_LIST } from './constant.ts'

export default function ModalConnectWallet() {
  const { globalModal, isModalOpen, onCloseModal } = useModal()
  const isOpen = globalModal === MODAL_LIST.CONNECT_WALLET && isModalOpen

  const { select: _connect, connecting, connected } = useMovementWallet()

  const connect = async (wallet: Readonly<IDefaultWallet>) => {
    try {
      await _connect(wallet.name)
    } catch (e) {
      console.error(e)
      if (wallet.downloadUrl) window.open(wallet.downloadUrl.browserExtension, '_blank')
    }
  }

  if (connected) return null

  return (
    <BaseModal isOpen={isOpen} onClose={onCloseModal} title="Connect your wallet">
      <div className="m-auto flex justify-between">
        <Button
          variant="light"
          className="flex h-[42px] w-[170px] items-center justify-start border-[0.5px] border-transparent p-0 px-4 data-[hover]:border-primaryHover "
          disableRipple
          disableAnimation
          isDisabled={connecting}
          onPress={() => connect(razor)}
        >
          <Image src={getWalletImagePath(razor.name)} className="w-[26px] min-w-[26px] rounded" />
          <Subtitle2 className="text-primaryHover">{razor.name}</Subtitle2>
        </Button>
        <Button
          variant="light"
          className="flex h-[42px] w-[170px] items-center justify-start border-[0.5px] border-transparent p-0 px-4 data-[hover]:border-primaryHover"
          disableRipple
          disableAnimation
          isDisabled={connecting}
          onPress={() => connect(nightly)}
        >
          <Image src={getWalletImagePath(nightly.name)} className="w-[26px] min-w-[26px] rounded" />
          <Subtitle2 className="text-primaryHover">{nightly.name}</Subtitle2>
        </Button>
      </div>

      <Spacer y={2} />

      <div className="m-auto flex justify-between">
        <Button
          variant="light"
          className="flex h-[42px] w-[170px] items-center justify-start border-[0.5px] border-transparent p-0 px-4 data-[hover]:border-primaryHover "
          disableRipple
          disableAnimation
          isDisabled={connecting}
          onPress={() => connect(okx)}
        >
          <Image src={getWalletImagePath(okx.name)} className="w-[26px] min-w-[26px] rounded" />
          <Subtitle2 className="text-primaryHover">{okx.name}</Subtitle2>
        </Button>
        <Button
          variant="light"
          className="flex h-[42px] w-[170px] items-center justify-start border-[0.5px] border-transparent p-0 px-4 data-[hover]:border-primaryHover "
          disableRipple
          disableAnimation
          isDisabled={connecting}
          onPress={() => connect(bitget)}
        >
          <Image src={getWalletImagePath(bitget.name)} className="w-[26px] min-w-[26px] rounded" />
          <Subtitle2 className="text-primaryHover">{bitget.name}</Subtitle2>
        </Button>
      </div>

      <Spacer y={4} />

      <div className="flex w-full items-center justify-center border-t-[0.5px] border-borderGrey px-5 py-3">
        <Body4 className="w-[35ch] text-center">
          By connecting your wallet, you agree to our <span className="text-primary">Terms of Use</span> and{' '}
          <span className="text-primary">Privacy Policy</span>.
        </Body4>
      </div>
    </BaseModal>
  )
}
