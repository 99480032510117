import CountdownSpinner from '@/components/CountdownSpinner.tsx'
import PageLayout from '@/components/PageLayout.tsx'
import { useTokenInOutInfo } from '@/hooks/limit/useTokenInOutInfo.ts'
import { useGetPoolFromUrl } from '@/hooks/liquidity/useGetLiqPools.ts'
import { Icon } from '@iconify/react'
import { useMovementNetworkStatus } from '@mosaicag/swap-widget'
import { Button, Skeleton, Slider, Spacer } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { NumericFormat } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import { ButtonBase } from '../../../components/Button.tsx'
import { SettingIcon } from '../../../components/Icons.tsx'
import { MODAL_LIST } from '../../../components/modals/constant.ts'
import { TokenImage } from '../../../components/TokenImage.tsx'
import TokenSelector from '../../../components/TokenSelector.tsx'
import { Body0, Body2, Body4, Headline5, Subtitle3 } from '../../../components/Typography.tsx'
import { BIP_BASE } from '../../../constants'
import { useSetTypedAmount } from '../../../hooks/common/input/useSetTypedAmount.ts'
import { useIsSufficientBalance } from '../../../hooks/common/token/useIsSufficientBalance.ts'
import { useParseTokenInOut } from '../../../hooks/common/token/useParseTokenByParam.ts'
import { useSwitchToken } from '../../../hooks/common/token/useSwitchToken.ts'
import { useTokenBalance } from '../../../hooks/common/token/useTokenBalance.ts'
import { useTokenPrice } from '../../../hooks/common/token/useTokenPrice.ts'
import { useTokenUsd } from '../../../hooks/common/token/useTokenUsd.ts'
import { useFeeAmount } from '../../../hooks/common/useFeeAmount.ts'
import { useResetTimerFn } from '../../../hooks/common/useResetTimerFn.ts'
import { useGetUserPosition } from '../../../hooks/contracts/liquidity/useGetUserPosition.ts'
import { useRemoveLiq } from '../../../hooks/contracts/liquidity/useRemoveLiq.ts'
import useMovementWallet from '../../../hooks/useMovementWallet.ts'
import { useModal } from '../../../provider/ModalProvider.tsx'
import { useAppSelector } from '../../../redux/hooks'
import { mulpowToFraction, numberWithCommas } from '../../../utils/number.ts'
import { RemoveLiquidityPanel } from './constant.ts'
import RemoveLiquiditySettings from './RemoveLiquiditySettings.tsx'

export default function RemoveLiquidity() {
  const { onOpenModal } = useModal()
  const { connected } = useMovementWallet()
  const navigate = useNavigate()

  const { address } = useMovementWallet()
  const { resetTimerFn, setResetTimerFn } = useResetTimerFn()
  const { isNetworkStable, isLoadingNetworkStatus } = useMovementNetworkStatus()

  const { token0SymbolOrAddress, token1SymbolOrAddress, redirectPair } = useParseTokenInOut('liquidity/remove')

  const {
    tokenInInfo: token0Info,
    tokenOutInfo: token1Info,
    tokenIn: token0,
    tokenOut: token1,
    tokenInDecimals: token0Decimals,
    tokenOutDecimals: token1Decimals,
  } = useTokenInOutInfo(token0SymbolOrAddress, token1SymbolOrAddress)

  const {
    typedAmount: typedAmountInToken0,
    setTypedAmount: setTypedAmountInToken0,
    fractionalAmount: fractionalAmountInToken0,
  } = useSetTypedAmount('0', token0Decimals)
  const {
    typedAmount: typedAmountInToken1,
    setTypedAmount: setTypedAmountInToken1,
    fractionalAmount: fractionalAmountInToken1,
  } = useSetTypedAmount('0', token1Decimals)

  const fractionalPriceToken0 = useTokenPrice(token0)
  const fractionalPriceToken1 = useTokenPrice(token1)

  const { fractionalTokenBalance: fractionalBalanceToken0 } = useTokenBalance(token0)
  const { fractionalTokenBalance: fractionalBalanceToken1 } = useTokenBalance(token1)

  const fractionalAmountInToken0Usd = useTokenUsd(fractionalAmountInToken0, fractionalPriceToken0)
  const fractionalAmountInToken1Usd = useTokenUsd(fractionalAmountInToken1, fractionalPriceToken1)

  const fractionalFeeAmount = useFeeAmount(token0)

  const isSufficientBalanceToken0 = useIsSufficientBalance(
    fractionalBalanceToken0,
    fractionalAmountInToken0,
    fractionalFeeAmount,
  )
  const isSufficientBalanceToken1 = useIsSufficientBalance(
    fractionalBalanceToken1,
    fractionalAmountInToken1,
    fractionalFeeAmount,
  )

  const [activePanel, setActivePanel] = useState(RemoveLiquidityPanel.RemoveLiquidity)
  const { setToken0, setToken1 } = useSwitchToken(
    token0SymbolOrAddress,
    token1SymbolOrAddress,
    fractionalAmountInToken0,
    fractionalAmountInToken1,
    redirectPair,
    setTypedAmountInToken0,
    setTypedAmountInToken1,
    () => setActivePanel(RemoveLiquidityPanel.RemoveLiquidity),
  )

  const swapCardRef = useRef<HTMLDivElement>(null)

  const [liqPercent, setLiqPercent] = useState(0)
  const [liqAmount, setLiqAmount] = useState('0')

  const { pool } = useGetPoolFromUrl()

  const {
    userLiq,
    pooledToken0,
    pooledToken1,
    poolShare,
    isValidating: isValidatingQuote,
    reFetch,
  } = useGetUserPosition(pool, token0Decimals, token1Decimals)

  const slippageBps = useAppSelector((state) => state.user.slippageBps)

  const minimumReceivedToken0 = useMemo(() => {
    if (!fractionalAmountInToken0) return undefined
    const str = fractionalAmountInToken0
      .multiply(BIP_BASE - slippageBps)
      .divide(BIP_BASE)
      .toFixed(18)
    return mulpowToFraction(str, token0Decimals)
  }, [fractionalAmountInToken0, slippageBps, token0Decimals])

  const minimumReceivedToken1 = useMemo(() => {
    if (!fractionalAmountInToken1) return undefined
    const str = fractionalAmountInToken1
      .multiply(BIP_BASE - slippageBps)
      .divide(BIP_BASE)
      .toFixed(18)
    return mulpowToFraction(str, token1Decimals)
  }, [fractionalAmountInToken1, slippageBps, token1Decimals])

  const { removeLiq: _onRemoveLiq, isRemoving } = useRemoveLiq()

  // todo can not remove 0.5
  const onRemoveLiq = async () => {
    if (!address) return
    await _onRemoveLiq({
      pool,
      lpVal: liqAmount.replaceAll(',', ''),
      amountXVal: fractionalAmountInToken0?.numerator.toString() || '0',
      amountXValMin: minimumReceivedToken0?.numerator.toString() || '0',
      amountYVal: fractionalAmountInToken1?.numerator.toString() || '0',
      amountYValMin: minimumReceivedToken1?.numerator.toString() || '0',
      recipient: address,
      tokenX: token0Info?.coinType || token0, // todo better
      tokenY: token1Info?.coinType || token1,
    })
  }

  useEffect(() => {
    resetTimerFn()
  }, [resetTimerFn, fractionalAmountInToken0, fractionalAmountInToken1, token0, token1, isValidatingQuote])

  const removeLiquidityButton = useMemo(() => {
    if (isRemoving) return { isDisabled: true, text: 'Removing liquidity...' }
    if (isLoadingNetworkStatus) return { isDisabled: true, text: 'Checking network status...' }
    if (!isNetworkStable) return { isDisabled: true, text: 'Network is not stable now' }
    if (isValidatingQuote) return { isDisabled: true, text: 'Validating quote...' }
    if (!fractionalAmountInToken0 || !fractionalAmountInToken1) return { isDisabled: true, text: 'Enter an amount' }
    if (fractionalAmountInToken0.isZero() || fractionalAmountInToken1.isZero())
      return {
        isDisabled: true,
        text: 'Enter an amount',
      }
    if (!isSufficientBalanceToken0 || !isSufficientBalanceToken1)
      return {
        isDisabled: true,
        text: 'Insufficient balance',
      }
    return { isDisabled: false, text: 'Remove Liquidity' }
  }, [
    fractionalAmountInToken0,
    fractionalAmountInToken1,
    isLoadingNetworkStatus,
    isNetworkStable,
    isRemoving,
    isSufficientBalanceToken0,
    isSufficientBalanceToken1,
    isValidatingQuote,
  ])

  return (
    <PageLayout ref={swapCardRef} className="relative">
      <Button
        isIconOnly
        className="absolute -left-10 h-full w-[32px] min-w-min rounded-md bg-transparent opacity-50 
             transition-all hover:py-1 hover:!text-white"
        onPress={() => {
          navigate('/liquidity/my-positions')
        }}
        disableAnimation
      >
        <Icon icon="mdi:chevron-left" color="#8B8D91" fontSize={24} />
      </Button>
      <div className="mb-2.5 flex justify-between">
        <div className="flex items-center gap-1">
          <Headline5 className="text-baseGrey">Remove Liquidity</Headline5>
        </div>
        <div className="flex justify-end">
          <Button
            isIconOnly
            className={'h-[32px] w-[32px] min-w-min bg-transparent'}
            disableAnimation
            onPress={async () => {
              if (!isValidatingQuote) await reFetch()
            }}
          >
            <CountdownSpinner
              timeInSeconds={10}
              onFinishCountdown={reFetch}
              setResetTimerFunc={setResetTimerFn}
              isLoading={isValidatingQuote || isRemoving}
              size={25}
            />
          </Button>
          <Button
            isIconOnly
            className="m-0 h-[32px] w-[32px] min-w-min bg-transparent p-0"
            onPress={() => setActivePanel(RemoveLiquidityPanel.RemoveLiquiditySettings)}
            disableAnimation
          >
            <SettingIcon size={24} color={'#8B8D91'} />
          </Button>
        </div>
      </div>

      <Body4 className="text-baseGrey">
        Removing pool tokens converts your position back into underlying tokens at the current rate, proportional to
        your share of the pool. Accrued fees are included in the amounts you receive.
      </Body4>

      <Spacer y={2} />

      {activePanel === RemoveLiquidityPanel.RemoveLiquidity && (
        <>
          <motion.div
            initial={{ translateX: 100, opacity: 0 }}
            animate={{ translateX: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <div className="relative flex flex-col gap-1">
              <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
                <Body4 className="text-baseGrey">Remove</Body4>

                <div className="flex items-center justify-between gap-3">
                  <NumericFormat
                    decimalSeparator="."
                    allowedDecimalSeparators={[',']}
                    thousandSeparator
                    inputMode="decimal"
                    autoComplete="off"
                    autoCorrect="off"
                    type="text"
                    placeholder="0"
                    minLength={1}
                    maxLength={30}
                    spellCheck="false"
                    className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey"
                    pattern="^[0-9]*[.,]?[0-9]*$"
                    allowNegative={false}
                    value={liqAmount}
                    onChange={(e) => {
                      setLiqAmount(e.currentTarget.value)
                      const percent = Number(e.currentTarget.value.replaceAll(',', '')) / Number(userLiq)
                      setLiqPercent(percent)
                      if (percent < 1e-6) {
                        setTypedAmountInToken0('0', token0Decimals)
                        setTypedAmountInToken1('0', token1Decimals)
                        return
                      }
                      setTypedAmountInToken0(
                        (Number(pooledToken0?.toSignificant(18)) * percent).toString() || '0',
                        token0Decimals,
                      )
                      setTypedAmountInToken1(
                        (Number(pooledToken1?.toSignificant(18)) * percent).toString() || '0',
                        token1Decimals,
                      )
                    }}
                  />

                  <Button
                    className="flex h-[35px] w-fit min-w-fit items-center gap-0 border-1 border-borderGrey bg-background p-0 transition"
                    disableAnimation
                    disableRipple
                  >
                    <div className="ml-1.5 flex">
                      <TokenImage
                        width={20}
                        height={20}
                        className="min-h-[20px] min-w-[20px]"
                        logoUrl={token0Info?.logoUrl}
                      />
                      <TokenImage
                        width={20}
                        height={20}
                        className="-ml-2 min-h-[20px] min-w-[20px]"
                        logoUrl={token1Info?.logoUrl}
                      />
                    </div>
                    <Body0 className="mr-1.5 whitespace-nowrap text-baseGrey">
                      {token0Info?.symbol ?? '--'}/{token1Info?.symbol ?? '--'}
                    </Body0>
                  </Button>
                </div>

                <Slider
                  showTooltip={true}
                  size="sm"
                  step={0.01}
                  formatOptions={{ style: 'percent' }}
                  maxValue={1}
                  minValue={0}
                  marks={[
                    {
                      value: 0,
                      label: '0%',
                    },
                    {
                      value: 0.25,
                      label: '25%',
                    },
                    {
                      value: 0.5,
                      label: '50%',
                    },
                    {
                      value: 0.75,
                      label: '75%',
                    },
                    {
                      value: 1,
                      label: '100%',
                    },
                  ]}
                  value={liqPercent}
                  onChange={(value) => {
                    let percent: number
                    if (typeof value === 'number') {
                      percent = Number(value)
                    } else {
                      percent = Number(value[0])
                    }
                    setLiqPercent(percent)
                    setLiqAmount((Number(userLiq) * percent).toString())
                    setTypedAmountInToken0(
                      (Number(pooledToken0?.toFixed(18)) * percent).toString() || '0',
                      token0Decimals,
                    )
                    setTypedAmountInToken1(
                      (Number(pooledToken1?.toFixed(18)) * percent).toString() || '0',
                      token1Decimals,
                    )
                  }}
                  renderThumb={(props) => (
                    <div
                      {...props}
                      className="top-1/2 flex h-5 w-5 cursor-grab items-center justify-center rounded-full border-0 shadow-small outline-none ring-transparent before:absolute before:h-11 before:w-11 before:rounded-full after:h-4 after:w-4 after:rounded-full after:bg-background after:shadow-small after:transition-all data-[focus-visible=true]:z-10 data-[focused=true]:z-10 data-[dragging=true]:cursor-grabbing data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-offset-2 data-[focus-visible=true]:outline-focus data-[dragging=true]:after:scale-80 motion-reduce:after:transition-none"
                    >
                      <img src="/images/slider_thumb.svg" alt="slider-thumb" className="h-6 w-6" />
                    </div>
                  )}
                  classNames={{
                    base: 'max-w-md',
                    mark: 'text-baseGrey',
                  }}
                  tooltipProps={{
                    offset: 10,
                    placement: 'top',
                    classNames: {
                      content: ['py-2 shadow-xl', 'text-baseBlack'],
                    },
                  }}
                />
              </div>

              {/* INPUT */}
              <>
                <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
                  <div className="flex items-center justify-between gap-3">
                    <NumericFormat
                      decimalSeparator="."
                      allowedDecimalSeparators={[',']}
                      thousandSeparator
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                      type="text"
                      placeholder="0.00"
                      minLength={1}
                      maxLength={30}
                      spellCheck="false"
                      className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey"
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      value={typedAmountInToken0}
                      allowNegative={false}
                      disabled
                    />
                    <Button
                      className="flex h-[35px] w-fit min-w-fit items-center gap-0 border-1 border-borderGrey bg-background p-0 transition"
                      disableAnimation
                      disableRipple
                      onPress={() => setActivePanel(RemoveLiquidityPanel.SelectToken0)}
                    >
                      <div className="ml-1.5">
                        <TokenImage
                          width={20}
                          height={20}
                          className="min-h-[20px] min-w-[20px]"
                          logoUrl={token0Info?.logoUrl}
                        />
                      </div>
                      <Body0 className="ml-1.5 whitespace-nowrap text-baseGrey">{token0Info?.symbol ?? '--'}</Body0>
                      <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={24} />
                    </Button>
                  </div>
                  <div className="flex items-center justify-between gap-3">
                    <Body4 className="text-baseGrey">
                      {fractionalAmountInToken0Usd
                        ? '~$' + numberWithCommas(fractionalAmountInToken0Usd.toSignificant(6), false, 2)
                        : '--'}
                    </Body4>
                  </div>
                </div>
              </>

              <div className="absolute left-1/2 top-1/2 z-[1] -translate-x-1/2 -translate-y-1/2">
                <Button
                  isIconOnly
                  className="h-[36px] min-h-[36px] w-[36px] min-w-[36px] rounded-lg border-[0.5px] border-borderGrey bg-background"
                >
                  <Icon icon="mdi:plus" color="#8B8D91" fontSize={22} />
                </Button>
              </div>
              {/* OUTPUT */}
              <>
                <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
                  <div className="flex items-center justify-between gap-3">
                    <NumericFormat
                      decimalSeparator="."
                      allowedDecimalSeparators={[',']}
                      thousandSeparator
                      inputMode="decimal"
                      autoComplete="off"
                      autoCorrect="off"
                      type="text"
                      placeholder="0.00"
                      minLength={1}
                      maxLength={30}
                      spellCheck="false"
                      className="w-full bg-transparent text-[36px] font-medium leading-[42px] text-white outline-none placeholder:text-baseGrey"
                      style={{ color: '#FFFFFF', WebkitTextFillColor: '#FFFFFF' }}
                      pattern="^[0-9]*[.,]?[0-9]*$"
                      data-tooltip-id="tooltip-input-amount-out"
                      value={typedAmountInToken1}
                      allowNegative={false}
                      disabled
                    />
                    <Button
                      className="flex h-[35px] w-fit min-w-fit items-center gap-0 border-1 border-borderGrey bg-background p-0 transition"
                      disableAnimation
                      disableRipple
                      onPress={() => setActivePanel(RemoveLiquidityPanel.SelectToken1)}
                    >
                      <div className="ml-1.5">
                        <TokenImage
                          width={20}
                          height={20}
                          className="min-h-[20px] min-w-[20px]"
                          logoUrl={token1Info?.logoUrl}
                        />
                      </div>
                      <Body0 className="ml-1.5 whitespace-nowrap text-baseGrey">{token1Info?.symbol ?? '--'}</Body0>
                      <Icon icon="mdi:chevron-down" color="#8B8D91" fontSize={24} />
                    </Button>
                  </div>
                  <div className="flex items-center justify-between gap-3">
                    <div className="flex items-center justify-between gap-1">
                      <Body4 className="text-baseGrey">
                        {fractionalAmountInToken1Usd
                          ? '~$' + numberWithCommas(fractionalAmountInToken1Usd.toSignificant(6), false, 2)
                          : '--'}
                      </Body4>
                    </div>
                  </div>
                </div>
              </>
            </div>

            <Spacer y={1} />

            {connected ? (
              <ButtonBase
                v={'primary'}
                className="h-[48px] min-h-[48px] w-full gap-0 rounded-[8px]"
                isDisabled={removeLiquidityButton.isDisabled}
                onClick={onRemoveLiq}
              >
                <Body2>{removeLiquidityButton.text}</Body2>
              </ButtonBase>
            ) : (
              <ButtonBase
                v="primary"
                className="h-[48px] min-h-[48px] w-full gap-0 rounded-[8px]"
                onPress={() => onOpenModal(MODAL_LIST.CONNECT_WALLET)}
              >
                <Body2>Connect Wallet</Body2>
              </ButtonBase>
            )}

            <div className="mt-1 flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
              <Subtitle3 className="text-white">Your position</Subtitle3>
              <div className="flex items-center justify-between gap-3">
                <Body4 className="text-baseGrey">Your total pool tokens</Body4>
                {isValidatingQuote ? (
                  <div className="flex h-[14.04px] w-[50px] items-center">
                    <Skeleton className="h-[14.04px] w-full rounded bg-baseGrey" />
                  </div>
                ) : (
                  <Body4 className="text-white">{userLiq ? numberWithCommas(userLiq.toString()) : '--'}</Body4>
                )}
              </div>
              <div className="flex items-center justify-between gap-3">
                <Body4 className="text-baseGrey">Pooled MOVE</Body4>
                {isValidatingQuote ? (
                  <div className="flex h-[14.04px] w-[50px] items-center">
                    <Skeleton className="h-[14.04px] w-full rounded bg-baseGrey" />
                  </div>
                ) : (
                  <Body4 className="text-white">
                    {pooledToken0?.toSignificant(6) ? numberWithCommas(pooledToken0?.toSignificant(6)) : '--'}
                  </Body4>
                )}
              </div>
              <div className="flex items-center justify-between gap-3">
                <Body4 className="text-baseGrey">Pooled USDC</Body4>
                {isValidatingQuote ? (
                  <div className="flex h-[14.04px] w-[50px] items-center">
                    <Skeleton className="h-[14.04px] w-full rounded bg-baseGrey" />
                  </div>
                ) : (
                  <Body4 className="text-white">
                    {pooledToken1?.toSignificant(6) ? numberWithCommas(pooledToken1?.toSignificant(6)) : '--'}
                  </Body4>
                )}
              </div>
              <div className="flex items-center justify-between gap-3">
                <Body4 className="text-baseGrey">Your pool share</Body4>
                {isValidatingQuote ? (
                  <div className="flex h-[14.04px] w-[50px] items-center">
                    <Skeleton className="h-[14.04px] w-full rounded bg-baseGrey" />
                  </div>
                ) : (
                  <Body4 className="text-white">
                    {poolShare ? numberWithCommas(poolShare.toString(), false, 2) + '%' : '--'}
                  </Body4>
                )}
              </div>
            </div>
          </motion.div>
        </>
      )}

      {activePanel === RemoveLiquidityPanel.SelectToken0 && (
        <TokenSelector
          swapCardRef={swapCardRef}
          onSelectToken={setToken0}
          onBack={() => setActivePanel(RemoveLiquidityPanel.RemoveLiquidity)}
        />
      )}

      {activePanel === RemoveLiquidityPanel.SelectToken1 && (
        <TokenSelector
          swapCardRef={swapCardRef}
          onSelectToken={setToken1}
          onBack={() => setActivePanel(RemoveLiquidityPanel.RemoveLiquidity)}
        />
      )}
      {activePanel === RemoveLiquidityPanel.RemoveLiquiditySettings && (
        <RemoveLiquiditySettings
          cardRef={swapCardRef}
          onBack={() => setActivePanel(RemoveLiquidityPanel.RemoveLiquidity)}
        />
      )}
    </PageLayout>
  )
}
