import { useFetchTokensPrice } from '@mosaicag/swap-widget'
import { useMemo } from 'react'
import { mulpowToFraction } from '../../utils/number.ts'
import { useTokenInOutInfo } from './useTokenInOutInfo.ts'

export function useTokenInOutPrice(tokenSymbolOrAddress0: string, tokenSymbolOrAddress1: string) {
  const { tokenIn, tokenOut } = useTokenInOutInfo(tokenSymbolOrAddress0, tokenSymbolOrAddress1)
  const params = useMemo(() => [tokenIn, tokenOut], [tokenIn, tokenOut])
  const { tokenPriceMap: followingPriceData } = useFetchTokensPrice(params)
  const fractionalPriceTokenIn = useMemo(
    () => (followingPriceData[tokenIn] ? mulpowToFraction(followingPriceData[tokenIn]) : undefined),
    [followingPriceData, tokenIn],
  )
  const fractionalPriceTokenOut = useMemo(
    () => (followingPriceData[tokenOut] ? mulpowToFraction(followingPriceData[tokenOut]) : undefined),
    [followingPriceData, tokenOut],
  )

  const fractionalTokenInPriceTokenOut = useMemo(
    () =>
      fractionalPriceTokenIn && fractionalPriceTokenOut
        ? fractionalPriceTokenIn.divide(fractionalPriceTokenOut)
        : undefined,
    [fractionalPriceTokenIn, fractionalPriceTokenOut],
  )

  const fractionalTokenOutPriceTokenIn = useMemo(
    () =>
      fractionalPriceTokenIn && fractionalPriceTokenOut
        ? fractionalPriceTokenOut.divide(fractionalPriceTokenIn)
        : undefined,
    [fractionalPriceTokenIn, fractionalPriceTokenOut],
  )

  return useMemo(
    () => ({
      fractionalPriceTokenIn,
      fractionalPriceTokenOut,
      fractionalTokenInPriceTokenOut,
      fractionalTokenOutPriceTokenIn,
    }),
    [fractionalPriceTokenIn, fractionalPriceTokenOut, fractionalTokenInPriceTokenOut, fractionalTokenOutPriceTokenIn],
  )
}
