import { useMemo } from 'react'
import useSWR from 'swr'
import { aptos } from '../../../constants'
import useMovementWallet from '../../useMovementWallet.ts'

const fn = async ({ user, pool }: { user: string | undefined; pool: string }) => {
  if (!user) return '0'
  const result: string[] = await aptos.viewJson({
    payload: {
      function: `0x1::primary_fungible_store::balance`,
      functionArguments: [user, pool],
      typeArguments: ['0x1::fungible_asset::Metadata'],
    },
  })
  return result[0]
}

export function useGetUserLiq(pool: string, userAddress?: string) {
  const { address } = useMovementWallet()

  const user = useMemo(() => {
    return userAddress || address
  }, [userAddress, address])

  const {
    data: userLiq,
    error,
    isValidating,
    mutate,
  } = useSWR(
    {
      key: `userLiq-${pool}-${user}`,
      pool,
      user,
    },
    fn,
  )
  return useMemo(() => {
    return { userLiq, error, isValidating, reFetch: mutate }
  }, [userLiq, error, isValidating, mutate])
}
