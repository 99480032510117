import { useRefreshBalance } from '@mosaicag/swap-widget'
import { memo, useEffect } from 'react'
import { useInterval } from 'usehooks-ts'

import { REFRESH_BALANCE_INTERVAL } from '../../constants'

function WalletUpdater() {
  const refreshBalance = useRefreshBalance()

  useEffect(() => {
    void refreshBalance()
  }, [refreshBalance])

  useInterval(refreshBalance, REFRESH_BALANCE_INTERVAL)

  return null
}

const MemoWalletUpdater = memo(WalletUpdater)
export default MemoWalletUpdater
