import { forwardRef } from 'react'

interface TextProps {
  className?: string
  [x: string]: any
}

export const Headline2 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-[36px] font-semibold leading-[42.23px] sm:text-[20px] sm:leading-[23.46px] ${className ?? ''}`}
    {...props}
  />
))
Headline2.displayName = 'Headline2'

export const Headline5 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-[20px] font-semibold leading-[23.46px] sm:text-[14px] sm:leading-[16.42px] ${className ?? ''}`}
    {...props}
  />
))
Headline5.displayName = 'Headline5'

export const Subtitle1 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-[18px] font-medium leading-[21.11px] sm:text-[14px] sm:leading-[16.42px] ${className ?? ''}`}
    {...props}
  />
))
Subtitle1.displayName = 'Subtitle1'

export const Subtitle2 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-[16px] font-medium leading-[18.77px] sm:text-[12px] sm:leading-[14.08px] ${className ?? ''}`}
    {...props}
  />
))
Subtitle2.displayName = 'Subtitle2'

export const Subtitle3 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-[14px] font-medium leading-[16.42px] sm:text-[12px] sm:leading-[14px] ${className ?? ''}`}
    {...props}
  />
))
Subtitle3.displayName = 'Subtitle3'

export const Body0 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-[20px] font-normal leading-[23.46px] sm:text-[14px] sm:leading-[16.42px] ${className ?? ''}`}
    {...props}
  />
))
Body0.displayName = 'Body0'

export const Body2 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-[16px] font-normal leading-[18.77px] sm:text-[14px] sm:leading-[16px] ${className ?? ''}`}
    {...props}
  />
))
Body2.displayName = 'Body2'

export const Body3 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`text-[14px] font-normal leading-[16.42px] sm:text-[12px] sm:leading-[14px] ${className ?? ''}`}
    {...props}
  />
))
Body3.displayName = 'Body3'

export const Body4 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div ref={ref} className={`text-[12px] font-normal leading-[14px] ${className ?? ''}`} {...props} />
))
Body4.displayName = 'Body4'

export const Body5 = forwardRef<HTMLDivElement, TextProps>(({ className, ...props }, ref) => (
  <div ref={ref} className={`text-[10px] font-normal leading-[11.73px] ${className ?? ''}`} {...props} />
))
Body5.displayName = 'Body5'
