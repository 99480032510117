import { useFullTokens, useTokenStore } from '@mosaicag/swap-widget'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Asset, MOVE, USDC } from '../../constants/asset.ts'
import { useAppDispatch } from '../../redux/hooks'

export function useParseTokenInOut(path: string) {
  const navigate = useNavigate()
  const location = useLocation()
  const [params] = useSearchParams()
  const dispatch = useAppDispatch()

  const tokenInSymbolOrAddress = useMemo(() => {
    try {
      return location.pathname.replace(`${path}/`, '').split('-')[0]
    } catch {
      navigate(`/limit/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [location.pathname, navigate, params, path])

  const tokenOutSymbolOrAddress = useMemo(() => {
    try {
      return location.pathname.replace(`${path}/`, '').split('-')[1]
    } catch {
      navigate(`/limit/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [location.pathname, navigate, params, path])

  const { data: fullTokenData } = useFullTokens()
  const { followingTokenData } = useTokenStore()

  useEffect(() => {
    const pair = location.pathname.replace('/limit/', '')
    try {
      const tokenInSymbolOrAddress = pair.split('-')[0]
      const tokenOutSymbolOrAddress = pair.split('-')[1]
      if (!tokenInSymbolOrAddress || !tokenOutSymbolOrAddress) throw new Error(`invalid pair = ${pair}`)

      const followingTokenDataList = Object.values(followingTokenData) as Asset[]

      if (!fullTokenData || Object.values(fullTokenData).length === 0) return
      const fullTokenDataList = Object.values(fullTokenData) as Asset[]

      const newTokenIn =
        fullTokenDataList.find((token) => token.id === tokenInSymbolOrAddress) ||
        followingTokenDataList.find((token) => token.symbol === tokenInSymbolOrAddress)
      const newTokenOut =
        fullTokenDataList.find((token) => token.id === tokenOutSymbolOrAddress) ||
        followingTokenDataList.find((token) => token.symbol === tokenOutSymbolOrAddress)
      if (!newTokenIn) throw new Error(`cannot find tokenIn = ${tokenInSymbolOrAddress}`)
      if (!newTokenOut) throw new Error(`cannot find tokenOut = ${tokenOutSymbolOrAddress}`)
    } catch (err) {
      pair !== '/limit' && console.error(err)
      navigate(`/limit/MOVE-USDC?${params.toString()}`, { replace: true })
    }
  }, [dispatch, followingTokenData, fullTokenData, location.pathname, navigate, params])

  const redirectPair = useCallback(
    (tokenInSymbolOrAddress: string, tokenOutSymbolOrAddress: string) => {
      navigate(`/limit/${tokenInSymbolOrAddress}-${tokenOutSymbolOrAddress}?${params.toString()}`, { replace: true })
    },
    [navigate, params],
  )

  return useMemo(
    () => ({
      tokenInSymbolOrAddress: tokenInSymbolOrAddress || MOVE.id,
      tokenOutSymbolOrAddress: tokenOutSymbolOrAddress || USDC.id,
      redirectPair,
    }),
    [redirectPair, tokenInSymbolOrAddress, tokenOutSymbolOrAddress],
  )
}
