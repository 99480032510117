import { useMemo } from 'react'
import { divpowToFraction } from '../../../utils/number.ts'
import { useGetPoolLiq } from './useGetPoolLiq.ts'
import { useGetUserLiq } from './useGetUserLiq.ts'
import { useQuoteRemoveLiq } from './useQuoteRemoveLiq.ts'

export function useGetUserPosition(pool: string, token0Decimals?: number, token1Decimals?: number) {
  const { userLiq, isValidating: isValidatingUserLiq, reFetch: reFetchUserLiq } = useGetUserLiq(pool)
  const { poolLiq, isValidating: isValidatingPoolLiq, reFetch: reFetchPoolLiq } = useGetPoolLiq(pool)
  const {
    quoteRemoveLiq: positionInfo,
    isValidating: isValudatingQuote,
    reFetch: reFetchQuote,
  } = useQuoteRemoveLiq(pool, userLiq || '0')

  const pooledToken0 = useMemo(() => {
    if (!positionInfo || !token0Decimals) return undefined
    return divpowToFraction(positionInfo.xToReturnVal, token0Decimals)
  }, [positionInfo, token0Decimals])

  const pooledToken1 = useMemo(() => {
    if (!positionInfo || !token1Decimals) return undefined
    return divpowToFraction(positionInfo.yToReturnVal, token1Decimals)
  }, [positionInfo, token1Decimals])

  const poolShare = useMemo(() => {
    if (!userLiq || !poolLiq) return undefined
    return (100 * Number(userLiq)) / Number(poolLiq)
  }, [poolLiq, userLiq])

  return useMemo(() => {
    return {
      userLiq,
      poolLiq,
      pooledToken0,
      pooledToken1,
      poolShare,
      isValidating: isValidatingUserLiq || isValidatingPoolLiq || isValudatingQuote,
      reFetch: async () => {
        await reFetchUserLiq()
        await reFetchPoolLiq()
        await reFetchQuote()
      },
    }
  }, [
    userLiq,
    poolLiq,
    pooledToken0,
    pooledToken1,
    poolShare,
    isValidatingUserLiq,
    isValidatingPoolLiq,
    isValudatingQuote,
    reFetchUserLiq,
    reFetchPoolLiq,
    reFetchQuote,
  ])
}
