import { useMemo } from 'react'

import { useTokenStore } from '@mosaicag/swap-widget'
import { Asset, MOVE } from '../../../constants/asset.ts'

// todo refactor vs useTokenInOutInfo
export function useTokenInfo(tokenSymbolOrAddress: string, defaultToken: Asset = MOVE) {
  const { followingTokenData } = useTokenStore()

  const tokenAddress = useMemo(
    () =>
      (Object.values(followingTokenData) as Asset[]).find((token) => {
        try {
          return token.symbol === tokenSymbolOrAddress || token.id === tokenSymbolOrAddress
        } catch {
          return false
        }
      })?.id || defaultToken.id,
    [defaultToken.id, followingTokenData, tokenSymbolOrAddress],
  )

  const tokenInfo: Asset | undefined = useMemo(
    () => followingTokenData[tokenAddress],
    [followingTokenData, tokenAddress],
  )

  return useMemo(
    () => ({
      tokenAddress,
      tokenInfo,
      tokenDecimals: tokenInfo?.decimals,
    }),
    [tokenAddress, tokenInfo],
  )
}
