import { useFullTokens } from '@mosaicag/swap-widget'
import { memo } from 'react'

function FullTokensUpdater() {
  useFullTokens() // Preload full tokens.
  return null
}

function TokenUpdater() {
  return <FullTokensUpdater />
}

const MemoTokenUpdater = memo(TokenUpdater)
export default MemoTokenUpdater
