import { useTokenBalance as useTokenBalanceMosaic } from '@mosaicag/swap-widget'
import { useMemo } from 'react'
import { parseFraction } from '../../../utils/number.ts'
import { useTokenInfo } from './useTokenInfo.ts'

export function useTokenBalance(tokenSymbolOrAddress: string) {
  const { tokenDecimals, tokenInfo } = useTokenInfo(tokenSymbolOrAddress)
  const tokenBalance = useTokenBalanceMosaic(tokenInfo)

  const fractionalTokenBalance = useMemo(
    () => parseFraction(tokenBalance, tokenDecimals),
    [tokenBalance, tokenDecimals],
  )

  return useMemo(
    () => ({
      tokenBalance,
      fractionalTokenBalance,
    }),
    [tokenBalance, fractionalTokenBalance],
  )
}
