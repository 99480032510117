import PageLayout from '@/components/PageLayout.tsx'
import { SelectTokenInput } from '@/components/SelectTokenInput.tsx'
import { useGetPoolFromUrl } from '@/hooks/liquidity/useGetLiqPools.ts'
import { Icon } from '@iconify/react'
import { useMovementNetworkStatus } from '@mosaicag/swap-widget'
import { Button, Spacer } from '@nextui-org/react'
import { motion } from 'framer-motion'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ButtonBase } from '../../../components/Button.tsx'
import CountdownSpinner from '../../../components/CountdownSpinner.tsx'
import { SettingIcon } from '../../../components/Icons.tsx'
import { MODAL_LIST } from '../../../components/modals/constant.ts'
import TokenSelector from '../../../components/TokenSelector.tsx'
import { Body2, Body4, Headline5 } from '../../../components/Typography.tsx'
import { BIP_BASE } from '../../../constants'
import { useSetPercentAmountIn } from '../../../hooks/common/input/useSetPercentAmountIn.ts'
import { useSetTypedAmount } from '../../../hooks/common/input/useSetTypedAmount.ts'
import { useIsSufficientBalance } from '../../../hooks/common/token/useIsSufficientBalance.ts'
import { useParseTokenInOut } from '../../../hooks/common/token/useParseTokenByParam.ts'
import { useSwitchToken } from '../../../hooks/common/token/useSwitchToken.ts'
import { useTokenBalance } from '../../../hooks/common/token/useTokenBalance.ts'
import { useTokenInfo } from '../../../hooks/common/token/useTokenInfo.ts'
import { useTokenPrice } from '../../../hooks/common/token/useTokenPrice.ts'
import { useTokenUsd } from '../../../hooks/common/token/useTokenUsd.ts'
import { useFeeAmount } from '../../../hooks/common/useFeeAmount.ts'
import { useResetTimerFn } from '../../../hooks/common/useResetTimerFn.ts'
import { useAddLiq } from '../../../hooks/contracts/liquidity/useAddLiq.ts'
import { useQuoteAddLiq } from '../../../hooks/contracts/liquidity/useQuoteAddLiq.ts'
import useMovementWallet from '../../../hooks/useMovementWallet.ts'
import { useModal } from '../../../provider/ModalProvider.tsx'
import { useAppSelector } from '../../../redux/hooks'
import { divpowToFraction, mulpowToFraction } from '../../../utils/number.ts'
import AddLiquiditySettings from './AddLiquiditySettings.tsx'
import { AddLiquidityPanel } from './constant.ts'
import { PositionInfo } from './PositionInfo.tsx'

export default function AddLiquidity() {
  const navigate = useNavigate()
  const { onOpenModal } = useModal()
  const { connected } = useMovementWallet()
  const { resetTimerFn, setResetTimerFn } = useResetTimerFn()
  const { isNetworkStable, isLoadingNetworkStatus } = useMovementNetworkStatus()

  const { token0SymbolOrAddress, token1SymbolOrAddress, redirectPair } = useParseTokenInOut('liquidity/add')
  const {
    tokenInfo: token0Info,
    tokenAddress: token0,
    tokenDecimals: token0Decimals,
  } = useTokenInfo(token0SymbolOrAddress)
  const {
    tokenInfo: token1Info,
    tokenAddress: token1,
    tokenDecimals: token1Decimals,
  } = useTokenInfo(token1SymbolOrAddress)

  const {
    typedAmount: typedAmountInToken0,
    setTypedAmount: setTypedAmountInToken0,
    fractionalAmount: fractionalAmountInToken0,
  } = useSetTypedAmount('1', token0Decimals)
  const {
    typedAmount: typedAmountInToken1,
    setTypedAmount: setTypedAmountInToken1,
    fractionalAmount: fractionalAmountInToken1,
  } = useSetTypedAmount('0', token1Decimals)

  const fractionalPriceToken0 = useTokenPrice(token0)
  const fractionalPriceToken1 = useTokenPrice(token1)

  const { fractionalTokenBalance: fractionalBalanceToken0 } = useTokenBalance(token0)
  const { fractionalTokenBalance: fractionalBalanceToken1 } = useTokenBalance(token1)

  const fractionalAmountInToken0Usd = useTokenUsd(fractionalAmountInToken0, fractionalPriceToken0)
  const fractionalAmountInToken1Usd = useTokenUsd(fractionalAmountInToken1, fractionalPriceToken1)

  const fractionalFeeAmount = useFeeAmount(token0)

  const onSetPercentAmountInToken0 = useSetPercentAmountIn(fractionalBalanceToken0, fractionalFeeAmount, token0Decimals)
  const onSetPercentAmountInToken1 = useSetPercentAmountIn(fractionalBalanceToken1, fractionalFeeAmount, token1Decimals)

  const isSufficientBalanceToken0 = useIsSufficientBalance(
    fractionalBalanceToken0,
    fractionalAmountInToken0,
    fractionalFeeAmount,
  )
  const isSufficientBalanceToken1 = useIsSufficientBalance(
    fractionalBalanceToken1,
    fractionalAmountInToken1,
    fractionalFeeAmount,
  )

  const [activePanel, setActivePanel] = useState(AddLiquidityPanel.AddLiquidity)

  const { setToken0, setToken1 } = useSwitchToken(
    token0SymbolOrAddress,
    token1SymbolOrAddress,
    fractionalAmountInToken0,
    fractionalAmountInToken1,
    redirectPair,
    setTypedAmountInToken0,
    setTypedAmountInToken1,
    () => setActivePanel(AddLiquidityPanel.AddLiquidity),
  )
  const swapCardRef = useRef<HTMLDivElement>(null)

  const { pool } = useGetPoolFromUrl()
  const [inputTokenId, setInputTokenId] = useState<string>(token0)

  const {
    quoteAddLiq,
    isValidating: isValidatingQuote,
    reFetch,
  } = useQuoteAddLiq(
    pool,
    inputTokenId === token0
      ? fractionalAmountInToken0?.numerator.toString()
      : fractionalAmountInToken1?.numerator.toString(),
    inputTokenId === token0,
  )

  useEffect(() => {
    if (quoteAddLiq) {
      if (inputTokenId === token0 && token1Decimals) {
        const fractionalAmountInToken1 = divpowToFraction(quoteAddLiq.otherTokenAmount.toString(), token1Decimals)
        setTypedAmountInToken1(fractionalAmountInToken1.toSignificant(6), token1Decimals)
      } else if (inputTokenId === token1 && token0Decimals) {
        const fractionalAmountInToken0 = divpowToFraction(quoteAddLiq.otherTokenAmount.toString(), token0Decimals)
        setTypedAmountInToken0(fractionalAmountInToken0.toSignificant(6), token0Decimals)
      }
    }
  }, [
    inputTokenId,
    quoteAddLiq,
    setTypedAmountInToken0,
    setTypedAmountInToken1,
    token0,
    token0Decimals,
    token1,
    token1Decimals,
  ])

  const slippageBps = useAppSelector((state) => state.user.slippageBps)

  const minimumReceivedToken0 = useMemo(() => {
    if (!fractionalAmountInToken0) return undefined
    const str = fractionalAmountInToken0
      .multiply(BIP_BASE - slippageBps)
      .divide(BIP_BASE)
      .toFixed(18)
    return mulpowToFraction(str, token0Decimals)
  }, [fractionalAmountInToken0, slippageBps, token0Decimals])

  const minimumReceivedToken1 = useMemo(() => {
    if (!fractionalAmountInToken1) return undefined
    const str = fractionalAmountInToken1
      .multiply(BIP_BASE - slippageBps)
      .divide(BIP_BASE)
      .toFixed(18)
    return mulpowToFraction(str, token1Decimals)
  }, [fractionalAmountInToken1, slippageBps, token1Decimals])

  const { addLiq: _onAddLiq, isAddingLiq } = useAddLiq()

  const onAddLiq = async () => {
    await _onAddLiq({
      pool: pool,
      amountXVal: fractionalAmountInToken0?.numerator.toString() || '0',
      amountXValMin: minimumReceivedToken0?.numerator.toString() || '0',
      amountYVal: fractionalAmountInToken1?.numerator.toString() || '0',
      amountYValMin: minimumReceivedToken1?.numerator.toString() || '0',
      tokenX: token0Info?.coinType || token0,
      tokenY: token1Info?.coinType || token1,
    })
  }

  useEffect(() => {
    resetTimerFn()
  }, [resetTimerFn, fractionalAmountInToken0, fractionalAmountInToken1, token0, token1, isValidatingQuote])

  const addLiquidityButton = useMemo(() => {
    if (isAddingLiq) return { isDisabled: true, text: 'Adding Liquidity...' }
    if (isLoadingNetworkStatus) return { isDisabled: true, text: 'Checking network status...' }
    if (!isNetworkStable) return { isDisabled: true, text: 'Network is not stable now' }
    if (isValidatingQuote) return { isDisabled: true, text: 'Validating quote...' }
    if (!fractionalAmountInToken0 || !fractionalAmountInToken1) return { isDisabled: true, text: 'Enter an amount' }
    if (fractionalAmountInToken0.isZero() || fractionalAmountInToken1.isZero())
      return {
        isDisabled: true,
        text: 'Enter an amount',
      }
    if (!isSufficientBalanceToken0 || !isSufficientBalanceToken1)
      return {
        isDisabled: true,
        text: 'Insufficient balance',
      }
    return { isDisabled: false, text: 'Add Liquidity' }
  }, [
    fractionalAmountInToken0,
    fractionalAmountInToken1,
    isAddingLiq,
    isLoadingNetworkStatus,
    isNetworkStable,
    isSufficientBalanceToken0,
    isSufficientBalanceToken1,
    isValidatingQuote,
  ])

  return (
    <PageLayout ref={swapCardRef} className="relative">
      <Button
        isIconOnly
        className="absolute -left-10 h-full w-[32px] min-w-min rounded-md bg-transparent opacity-50 
             transition-all hover:py-1 hover:!text-white"
        onPress={() => {
          navigate('/liquidity/my-positions')
        }}
        disableAnimation
      >
        {/*<div className="flex items-start h-full py-1">*/}
        <Icon icon="mdi:chevron-left" color="#8B8D91" fontSize={24} />
        {/*</div>*/}
      </Button>
      <div className="mb-2.5 flex justify-between">
        <div className="flex items-center gap-1">
          <Headline5 className="text-baseGrey">Add Liquidity</Headline5>
        </div>
        <div className="flex justify-end">
          <Button
            isIconOnly
            className={'h-[32px] w-[32px] min-w-min bg-transparent'}
            disableAnimation
            onPress={async () => {
              if (!isValidatingQuote) await reFetch()
            }}
          >
            <CountdownSpinner
              timeInSeconds={10}
              onFinishCountdown={reFetch}
              setResetTimerFunc={setResetTimerFn}
              isLoading={isValidatingQuote || isAddingLiq}
              size={25}
            />
          </Button>
          <Button
            isIconOnly
            className="m-0 h-[32px] w-[32px] min-w-min bg-transparent p-0"
            onPress={() => setActivePanel(AddLiquidityPanel.AddLiquiditySettings)}
            disableAnimation
          >
            <SettingIcon size={24} color={'#8B8D91'} />
          </Button>
        </div>
      </div>

      <Body4 className="text-baseGrey">
        When you add liquidity, you will receive pool tokens representing your position. These tokens automatically earn
        fees proportional to your share of the pool, and can be redeemed at any time.
      </Body4>

      <Spacer y={2} />

      {activePanel === AddLiquidityPanel.AddLiquidity && (
        <>
          <motion.div
            initial={{ translateX: 10, opacity: 0 }}
            animate={{ translateX: 0, opacity: 1 }}
            transition={{ duration: 0.2 }}
          >
            <div className="relative flex flex-col gap-1">
              {/* INPUT */}
              <SelectTokenInput
                tokenInInfo={token0Info}
                balance={fractionalBalanceToken0}
                label=""
                onMax={() => onSetPercentAmountInToken0(100, setTypedAmountInToken0)}
                connected={!!connected}
                inputValue={typedAmountInToken0}
                onChangeInput={(e) => {
                  setTypedAmountInToken0(e.currentTarget.value, token0Decimals)
                  setInputTokenId(token0)
                }}
                amountUsd={fractionalAmountInToken0Usd}
                onClickToken={() => setActivePanel(AddLiquidityPanel.SelectToken0)}
              />

              <div className="absolute left-1/2 top-1/2 z-[1] -translate-x-1/2 -translate-y-1/2">
                <Button
                  isIconOnly
                  className="h-[36px] min-h-[36px] w-[36px] min-w-[36px] rounded-lg border-[0.5px] border-borderGrey bg-background"
                >
                  <Icon icon="mdi:plus" color="#8B8D91" fontSize={22} />
                </Button>
              </div>

              {/* OUTPUT */}
              <SelectTokenInput
                tokenInInfo={token1Info}
                balance={fractionalBalanceToken1}
                label=""
                onMax={() => onSetPercentAmountInToken1(100, setTypedAmountInToken1)}
                connected={!!connected}
                inputValue={typedAmountInToken1}
                onChangeInput={(e) => {
                  setTypedAmountInToken1(e.currentTarget.value, token1Decimals)
                  setInputTokenId(token1)
                }}
                amountUsd={fractionalAmountInToken1Usd}
                onClickToken={() => setActivePanel(AddLiquidityPanel.SelectToken1)}
              />
            </div>

            <PositionInfo
              pool={pool}
              token0Symbol={token0Info?.symbol ?? '--'}
              token1Symbol={token1Info?.symbol ?? '--'}
              lpAmount={quoteAddLiq?.lpAmount}
              isValidatingQuote={isValidatingQuote}
              fractionalAmountInToken0={fractionalAmountInToken0}
              fractionalAmountInToken1={fractionalAmountInToken1}
            />

            <Spacer y={1} />

            {connected ? (
              <ButtonBase
                v="primary"
                className="h-[48px] min-h-[48px] w-full gap-0 rounded-[8px]"
                isDisabled={addLiquidityButton.isDisabled}
                onClick={onAddLiq}
              >
                <Body2>{addLiquidityButton.text}</Body2>
              </ButtonBase>
            ) : (
              <ButtonBase
                v="primary"
                className="h-[48px] min-h-[48px] w-full gap-0 rounded-[8px]"
                onPress={() => onOpenModal(MODAL_LIST.CONNECT_WALLET)}
              >
                <Body2>Connect Wallet</Body2>
              </ButtonBase>
            )}
          </motion.div>
        </>
      )}

      {activePanel === AddLiquidityPanel.SelectToken0 && (
        <TokenSelector
          swapCardRef={swapCardRef}
          onSelectToken={setToken0}
          onBack={() => setActivePanel(AddLiquidityPanel.AddLiquidity)}
        />
      )}
      {activePanel === AddLiquidityPanel.SelectToken1 && (
        <TokenSelector
          swapCardRef={swapCardRef}
          onSelectToken={setToken1}
          onBack={() => setActivePanel(AddLiquidityPanel.AddLiquidity)}
        />
      )}

      {activePanel === AddLiquidityPanel.AddLiquiditySettings && (
        <AddLiquiditySettings cardRef={swapCardRef} onBack={() => setActivePanel(AddLiquidityPanel.AddLiquidity)} />
      )}
    </PageLayout>
  )
}
