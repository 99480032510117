import { useMovementNetworkStatus } from '@mosaicag/swap-widget'
import { useMemo } from 'react'
import { Fraction } from '../../utils/fraction.ts'

export function useButtonText(
  fractionalAmountIn: Fraction | undefined,
  fractionalBalanceTokenIn: Fraction | undefined,
  fractionalFeeAmount: Fraction,
  contractParamSize: Fraction | undefined,
) {
  const { isNetworkStable, isLoadingNetworkStatus } = useMovementNetworkStatus()

  const isSufficientBalance = useMemo(
    () =>
      fractionalBalanceTokenIn && fractionalAmountIn
        ? fractionalBalanceTokenIn.subtract(fractionalFeeAmount).equalTo(fractionalAmountIn) ||
          fractionalBalanceTokenIn.subtract(fractionalFeeAmount).greaterThan(fractionalAmountIn)
        : undefined,
    [fractionalBalanceTokenIn, fractionalFeeAmount, fractionalAmountIn],
  )

  return useMemo(() => {
    if (isLoadingNetworkStatus) return { isDisabled: true, text: 'Checking network status...' }
    if (!isNetworkStable) return { isDisabled: true, text: 'Network is not stable now' }
    if (!fractionalAmountIn) return { isDisabled: true, text: 'Enter an amount' }
    if (!contractParamSize)
      //|| contractParamSize.lessThan(new Fraction(5)))
      return { isDisabled: true, text: 'Amount too small' }
    if (!isSufficientBalance) return { isDisabled: true, text: 'Insufficient balance' }
    return { isDisabled: false, text: 'Place Limit Order' }
  }, [isLoadingNetworkStatus, isNetworkStable, fractionalAmountIn, contractParamSize, isSufficientBalance])
}
