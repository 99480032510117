import { Button, Image, Link, Modal, ModalContent, Tab, Tabs } from '@nextui-org/react'
import React, { useMemo, useState } from 'react'

import { router } from '@/App.tsx'
import Copy from '@/components/Copy.tsx'
import APP_PATHS from '@/constants/path.ts'
import { Icon } from '@iconify/react'
import { Asset, sortBalanceFn, useTokensHasBalance, useTokenStore } from '@mosaicag/swap-widget'
import { isDesktop, isTablet } from 'react-device-detect'
import { getWalletImagePath } from '../../constants/wallet.ts'
import useMovementWallet from '../../hooks/useMovementWallet.ts'
import { useModal } from '../../provider/ModalProvider.tsx'
import { useAppSelector } from '../../redux/hooks'
import { ITransactionHistory } from '../../redux/slices/user.ts'
import { Fraction } from '../../utils/fraction.ts'
import { numberWithCommas } from '../../utils/number.ts'
import { getDisplayPeriod } from '../../utils/time.ts'
import { getShortAddress } from '../../utils/token.ts'
import BasicTokenInfo from '../BasicTokenInfo.tsx'
import { AssetWithBalance } from '../TokenSelector.tsx'
import { Body2, Body3, Body4, Headline2 } from '../Typography.tsx'
import { MODAL_LIST } from './constant.ts'

interface TransactionHistoryWithLogoUrl extends ITransactionHistory {
  tokenLogoIn: string
  tokenLogoOut: string
}

const useFormatHistory = () => {
  const { followingTokenData } = useTokenStore()

  const txHistoryMap = useAppSelector((state) => state.user.txHistoryMap)
  const renderTransactionHistories = useMemo(() => {
    const transactionHistories = Object.values(txHistoryMap)
    const followingTokenDataList = Object.values(followingTokenData) as Asset[]
    return transactionHistories
      .map((transactionHistory) => {
        if (!transactionHistory) return null
        const tokenLogoIn =
          followingTokenDataList.find((token) => token?.id === transactionHistory.tokenInAddress)?.logoUrl ?? ''
        const tokenLogoOut =
          followingTokenDataList.find((token) => token?.id === transactionHistory.tokenOutAddress)?.logoUrl ?? ''
        const res: TransactionHistoryWithLogoUrl = { ...transactionHistory, tokenLogoIn, tokenLogoOut }
        return res
      })
      .filter(Boolean)
  }, [txHistoryMap, followingTokenData])

  return renderTransactionHistories as TransactionHistoryWithLogoUrl[]
}

const ModalAssetsAndActivities: React.FC = () => {
  const { globalModal, isModalOpen, onOpenChangeModal, onCloseModal } = useModal()
  const isOpen = globalModal === MODAL_LIST.ACTIVITIES && isModalOpen
  const [isShowBalance, setShowBalance] = useState(false)
  const { account, name, disconnect } = useMovementWallet()

  const assets = useTokensHasBalance()

  const assetTokenList = useMemo(() => {
    const list = Object.values(assets) as AssetWithBalance[]
    return list.sort(sortBalanceFn)
  }, [assets])

  const renderTransactionHistories = useFormatHistory()

  const totalBalanceInUSD = useMemo(() => {
    if (!assets) return 0
    return (Object.values(assets) as AssetWithBalance[]).reduce((prev, curr) => {
      let rs = prev
      if (curr?.faBalanceUsd) rs = rs.add(curr.faBalanceUsd)
      if (curr?.coinBalanceUsd) rs = rs.add(curr.coinBalanceUsd)
      return rs
    }, new Fraction(0))
  }, [assets])

  if (!account) return null

  return (
    <Modal
      scrollBehavior="inside"
      isOpen={isOpen}
      onOpenChange={onOpenChangeModal}
      placement="center"
      backdrop="transparent"
      size="full"
      classNames={{
        wrapper: 'flex justify-end',
      }}
      motionProps={{
        variants: {
          enter: {
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.3,
              ease: 'easeOut',
            },
          },
          exit: {
            x: 50,
            opacity: 0,
            transition: {
              duration: 0.2,
              ease: 'easeIn',
            },
          },
        },
      }}
      className={`h-screen max-h-screen w-full ${isTablet || isDesktop ? 'max-w-[400px]' : 'max-w-full'}`}
      hideCloseButton
    >
      <ModalContent className="flex min-h-fit flex-col !rounded-xl bg-baseGrey1 pt-6 dark">
        <div className="mb-6 flex flex-row justify-between px-6">
          <div className="flex flex-row items-center gap-2">
            {name && <Image src={getWalletImagePath(name)} width={18} />}
            <div className="text-white">{getShortAddress(account.address)}</div>
            <div className="flex gap-2">
              <Copy size={20} value={account.address} />
              <Link
                href={`https://explorer.movementnetwork.xyz/account/${account.address}?network=porto+testnet`}
                isExternal
                className="text-baseGrey"
              >
                <Icon icon="iconamoon:link-external" fontSize={19} color="text-baseGrey" />
              </Link>
              <Button
                className="h-[20px] w-[20px] min-w-0 flex-none justify-end bg-transparent p-0"
                isIconOnly
                onPress={() => {
                  onCloseModal()
                  void disconnect()
                }}
              >
                <Icon icon="ph:power-bold" fontSize={20} className="text-baseGrey" />
              </Button>
            </div>
          </div>
          <Button
            className="h-[20px] w-[20px] min-w-0 flex-none justify-end bg-transparent p-0"
            isIconOnly
            onPress={onCloseModal}
          >
            <Icon icon="mdi:close" fontSize={22} className="text-baseGrey" />
          </Button>
        </div>
        <div className="select-none mx-6 mb-[24px] flex flex-col gap-1 rounded-[10px] bg-background p-3">
          <div className="flex items-center gap-2">
            <Body4 className="text-baseGrey">Total Balance</Body4>
            {isShowBalance ? (
              <Icon
                icon="mdi:eye-off-outline"
                fontSize={20}
                className="text-baseGrey cursor-pointer"
                onClick={() => setShowBalance(!isShowBalance)}
              />
            ) : (
              <Icon
                icon="lucide:eye"
                fontSize={20}
                className="text-baseGrey cursor-pointer"
                onClick={() => setShowBalance(!isShowBalance)}
              />
            )}
          </div>
          <Headline2 className="text-white">
            {isShowBalance
              ? totalBalanceInUSD
                ? `$${numberWithCommas(totalBalanceInUSD?.toSignificant(6), false)}`
                : undefined
              : '*** ***'}
          </Headline2>
        </div>
        <div className="flex w-full flex-1 flex-col overflow-auto dark">
          <Tabs
            radius="lg"
            variant="light"
            size="md"
            color="primary"
            className="tab-container pl-6"
            classNames={{
              tabList: 'rounded-lg p-0',
              cursor: 'rounded-lg px-0',
            }}
          >
            <Tab key="assets" title="Assets" className="h-[32px]">
              <div className="h-fit bg-baseGrey1">
                {assetTokenList.length === 0 ? (
                  <Body2 className="pt-4 text-center">No asset found</Body2>
                ) : (
                  assetTokenList.map((assetToken) => (
                    <AssetRow key={assetToken.id + assetToken.type} token={assetToken} />
                  ))
                )}
              </div>
            </Tab>
            <Tab key="activity" title="Activity" className="h-[32px]">
              <div>
                {renderTransactionHistories.length === 0 ? (
                  <Body2 className="mt-16 h-full text-center text-white">No activity found</Body2>
                ) : (
                  renderTransactionHistories
                    .sort((a, b) => b.timestamp - a.timestamp)
                    .map((transactionHistory) => {
                      return <ActivityRow key={transactionHistory.timestamp} transactionHistory={transactionHistory} />
                    })
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </ModalContent>
    </Modal>
  )
}

function AssetRow({ token }: { token: AssetWithBalance }) {
  const pathname = window.location.pathname
  return (
    <div className="w-full px-6 py-3 hover:bg-baseBlack">
      <BasicTokenInfo
        token={token}
        onClick={() => {
          if (pathname.startsWith(APP_PATHS.SWAP))
            router.navigate(`${APP_PATHS.SWAP}/${token.id + '-' + pathname.split('-')[1]}`).catch(() => {})
        }}
      />
    </div>
  )
}

function ActivityRow({ transactionHistory }: { transactionHistory: TransactionHistoryWithLogoUrl }) {
  const [tokenInLogoSrc, setTokenInLogoSrc] = useState(transactionHistory.tokenLogoIn || '/images/404.svg') // todo use token images
  const [tokenOutLogoSrc, setTokenOutLogoSrc] = useState(transactionHistory.tokenLogoOut || '/images/404.svg')
  return (
    <div className="flex flex-row items-center justify-between px-6 py-2 hover:bg-baseBlack">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row items-center gap-1">
          <Body3 className="text-white">Swapped</Body3>
          <Link
            href={`https://explorer.movementnetwork.xyz/txn/${transactionHistory.txHash}?network=porto+testnet`}
            isExternal
            className="text-baseGrey"
            showAnchorIcon
          />
        </div>
        <div className="flex flex-wrap items-center gap-1">
          <Image width={16} height={16} src={tokenInLogoSrc} onError={() => setTokenInLogoSrc('/images/404.svg')} />
          <Body4 className="text-baseGrey">
            {transactionHistory.readableAmountIn} {transactionHistory.tokenInSymbol} to{' '}
          </Body4>
          <Image width={16} height={16} src={tokenOutLogoSrc} onError={() => setTokenOutLogoSrc('/images/404.svg')} />
          <Body4 className="text-baseGrey">
            {transactionHistory.readableAmountOut} {transactionHistory.tokenOutSymbol}
          </Body4>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col items-end gap-1">
          <Body3 className="leading-5 text-white">{transactionHistory.isSuccess ? 'Success' : 'Failed'}</Body3>
          <Body4 className="text-baseGrey">{getDisplayPeriod(Date.now() - transactionHistory.timestamp)}</Body4>
        </div>
      </div>
    </div>
  )
}

export default ModalAssetsAndActivities
