import { Skeleton } from '@nextui-org/react'
import { useMemo } from 'react'
import { Body4, Subtitle1 } from '../../../components/Typography.tsx'
import { useGetPoolLiq } from '../../../hooks/contracts/liquidity/useGetPoolLiq.ts'
import { Fraction } from '../../../utils/fraction.ts'
import { numberWithCommas } from '../../../utils/number.ts'

export function PositionInfo({
  pool,
  lpAmount,
  token0Symbol,
  token1Symbol,
  isValidatingQuote,
  fractionalAmountInToken0,
  fractionalAmountInToken1,
}: {
  pool: string
  lpAmount?: string
  token0Symbol: string
  token1Symbol: string
  isValidatingQuote: boolean
  fractionalAmountInToken0?: Fraction
  fractionalAmountInToken1?: Fraction
}) {
  const { poolLiq } = useGetPoolLiq(pool)

  console.log({ poolLiq })

  const price = useMemo(() => {
    if (!fractionalAmountInToken0 || !fractionalAmountInToken1) return '0'
    const zero = new Fraction(0)
    if (fractionalAmountInToken1.equalTo(zero)) return '0'

    return fractionalAmountInToken0.divide(fractionalAmountInToken1).toSignificant(6)
  }, [fractionalAmountInToken0, fractionalAmountInToken1])

  const invertedPrice = useMemo(() => {
    if (!Number(price)) return 0
    return 1 / Number(price)
  }, [price])

  const poolShare = useMemo(() => {
    if (!lpAmount || !poolLiq) return 0
    return (100 * Number(lpAmount)) / (Number(poolLiq) + Number(lpAmount))
  }, [lpAmount, poolLiq])

  return (
    <div className="mt-1">
      <div className="flex flex-col gap-2 rounded-lg border-[0.5px] border-borderGrey bg-baseGrey1 p-3">
        <Body4 className="text-baseGrey">Price and pool share</Body4>
        <div className="grid grid-cols-3 gap-8 sm:gap-3">
          <div className="flex flex-col items-center gap-1">
            {isValidatingQuote ? (
              <>
                <div className="flex h-[21px] w-[50px] items-center">
                  <Skeleton className="h-[21px] w-full rounded bg-baseGrey" />
                </div>
              </>
            ) : (
              <Subtitle1 className="text-white">
                {price ? numberWithCommas(price.toString(), false, 6) : '--'}
              </Subtitle1>
            )}
            <Body4 className="text-baseGrey">
              {token0Symbol} per {token1Symbol}
            </Body4>
          </div>
          <div className="flex flex-col items-center gap-1">
            {isValidatingQuote ? (
              <>
                <div className="flex h-[21px] w-[50px] items-center">
                  <Skeleton className="h-[21px] w-full rounded bg-baseGrey" />
                </div>
              </>
            ) : (
              <Subtitle1 className="text-white">
                {invertedPrice ? numberWithCommas(invertedPrice.toString(), false, 6) : '--'}
              </Subtitle1>
            )}
            <Body4 className="text-baseGrey">
              {token1Symbol} per {token0Symbol}
            </Body4>
          </div>
          <div className="flex flex-col items-center gap-1">
            {isValidatingQuote ? (
              <>
                <div className="flex h-[21px] w-[50px] items-center">
                  <Skeleton className="h-[21px] w-full rounded bg-baseGrey" />
                </div>
              </>
            ) : (
              <Subtitle1 className="text-white">{numberWithCommas(poolShare.toString(), false, 2)}%</Subtitle1>
            )}
            <Body4 className="text-baseGrey">Share of pool</Body4>
          </div>
        </div>
      </div>
    </div>
  )
}
