import { useFetchTokensPrice } from '@mosaicag/swap-widget'
import { useMemo } from 'react'
import { mulpowToFraction } from '../../../utils/number.ts'
import { useTokenInfo } from './useTokenInfo.ts'

export function useTokenPrice(tokenSymbolOrAddress: string) {
  const { tokenAddress } = useTokenInfo(tokenSymbolOrAddress)
  const params = useMemo(() => [tokenAddress], [tokenAddress])
  const { tokenPriceMap: followingPriceData } = useFetchTokensPrice(params)

  const fractionalPriceToken = useMemo(
    () => (followingPriceData[tokenAddress] ? mulpowToFraction(followingPriceData[tokenAddress]) : undefined),
    [followingPriceData, tokenAddress],
  )
  return useMemo(() => fractionalPriceToken, [fractionalPriceToken])
}
