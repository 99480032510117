import { ReactNode, useEffect } from 'react'
import { createBrowserRouter, Navigate, RouterProvider, useSearchParams } from 'react-router-dom'
import Footer from './components/Footer.tsx'
import Header from './components/Header.tsx'
import LimitPage from './pages/LimitPage.tsx'
import AddLiquidity from './pages/liquidity/add-liquidity/AddLiquidity.tsx'
import Explore from './pages/liquidity/explore/Explore.tsx'
import MyPositions from './pages/liquidity/my-positions/MyPositions.tsx'
import RemoveLiquidity from './pages/liquidity/remove-liquidity/RemoveLiquidity.tsx'
import SwapPage from './pages/swap/SwapPage.tsx'

function NavigateWithParams({ to }: { to: string }) {
  const [params] = useSearchParams()
  return <Navigate to={{ pathname: to, search: `?${params.toString()}` }} />
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <NavigateWithParams to="/swap/MOVE-USDC" />,
  },
  {
    path: '/swap/:pair',
    element: (
      <AppLayout title="Swap">
        <SwapPage />
      </AppLayout>
    ),
  },
  {
    path: '/liquidity/add/:pair',
    element: (
      <AppLayout title="Liquidity">
        <AddLiquidity />
      </AppLayout>
    ),
  },
  {
    path: '/liquidity/remove/:pair',
    element: (
      <AppLayout title="Liquidity">
        <RemoveLiquidity />
      </AppLayout>
    ),
  },
  {
    path: '/liquidity/explore',
    element: (
      <AppLayout title="Liquidity">
        <Explore />
      </AppLayout>
    ),
  },
  {
    path: '/liquidity/my-positions',
    element: (
      <AppLayout title="Liquidity">
        <MyPositions />
      </AppLayout>
    ),
  },
  {
    path: '/liquidity',
    element: <NavigateWithParams to="/liquidity/explore" />,
  },
  {
    path: '/limit/:pair',
    element: (
      <AppLayout>
        <LimitPage />
      </AppLayout>
    ),
  },
  {
    path: '*',
    element: <NavigateWithParams to="/swap/MOVE-USDC" />,
  },
])

function AppLayout({ title, children }: { title?: string; children: ReactNode }) {
  useEffect(() => {
    if (title) {
      document.title = 'Mosaic | ' + title
    } else {
      document.title = 'Mosaic'
    }
  }, [title])

  return (
    <>
      <div className="min-h-screen w-screen dark">
        <div className="flex min-h-screen w-screen flex-col">
          <Header />
          <main className="isolate flex flex-col">
            {/* <div className="mt-[20px] w-full">
              <Announcement />
            </div> */}
            {children}
          </main>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default function App() {
  return <RouterProvider router={router} />
}
