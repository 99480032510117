import { useShowToastWithExplorerLink } from '@/hooks/useSwapNotificationFn.tsx'
import { useTokenStore } from '@mosaicag/swap-widget'
import { useCallback } from 'react'
import { divpowToFraction } from '../../utils/number.ts'

export default function useAddLiqNotificationFn() {
  const { followingTokenData } = useTokenStore()
  const showNotification = useShowToastWithExplorerLink()
  return useCallback(
    ({
      tokenX,
      tokenY,
      amountX,
      amountY,
      txHash,
      isSuccess,
      details,
      isRemove,
    }: {
      tokenX: string
      tokenY: string
      amountX: string
      amountY: string
      txHash?: string | undefined
      isSuccess: boolean
      details: any
      isRemove: boolean
    }) => {
      const tokenXData = followingTokenData[tokenX]
      const tokenYData = followingTokenData[tokenY]

      if (tokenXData && tokenYData) {
        const payload = {
          txHash: txHash,
          isSuccess,
          details,
          tokenXSymbol: tokenXData.symbol,
          tokenYSymbol: tokenYData.symbol,
          readableAmountX: divpowToFraction(amountX, tokenXData.decimals).toSignificant(6),
          readableAmountY: divpowToFraction(amountY, tokenYData.decimals).toSignificant(6),
          timestamp: Date.now(),
          tokenXAddress: tokenXData.id,
          tokenYAddress: tokenYData.id,
        }
        showNotification({
          isSuccess: payload.isSuccess,
          txHash,
          msg: payload.isSuccess
            ? `${isRemove ? 'Removed' : 'Added'} Liquidity ${payload.readableAmountX} ${payload.tokenXSymbol} ${payload.readableAmountY} ${payload.tokenYSymbol}`
            : `Error ${isRemove ? 'removing' : 'adding'} liquidity for ${payload.tokenXSymbol}/${payload.tokenYSymbol}`,
          error: details,
        })
      }
    },
    [followingTokenData, showNotification],
  )
}
