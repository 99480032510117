import React, { forwardRef } from 'react'

type Props = { classname?: string } & React.HTMLAttributes<HTMLDivElement>
const PageLayout = forwardRef<HTMLDivElement, Props>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={`mx-auto flex w-full max-w-[450px] flex-col py-[40px] px-4 sm:max-w-full ${className ?? ''}`}
    {...props}
  >
    {props.children}
  </div>
))
PageLayout.displayName = 'PageLayout'

export default PageLayout
