import { useRefreshBalance, useTokenStore } from '@mosaicag/swap-widget'
import { Link } from '@nextui-org/react'
import { useCallback } from 'react'
import { isDesktop } from 'react-device-detect'
import { toast } from 'react-toastify'
import { Body2 } from '../components/Typography'
import { useAppDispatch } from '../redux/hooks'
import { addTransactionHistory, ITransactionHistory } from '../redux/slices/user'
import { divpowToFraction } from '../utils/number'

const mapError: Record<string, string> = {
  'submitResponse.status REJECTED': 'User rejected transaction',
}
const formatError = (err: any) => {
  const jsonErr = JSON.stringify(err || {})
  if (jsonErr !== `{"code":"WALLET.SIGN_TX_ERROR"}`) {
    let errorDetails: string | undefined
    if (typeof err === 'string') {
      errorDetails = err
    } else {
      errorDetails = (err as any)?.message || undefined
    }

    return mapError[errorDetails ?? ''] || errorDetails
  }
  return ''
}

export const useShowToastWithExplorerLink = () => {
  const refreshBalance = useRefreshBalance()
  return useCallback(
    ({
      isSuccess,
      txHash,
      msg,
      error,
    }: {
      isSuccess: boolean
      msg: string
      txHash?: string | undefined
      error?: any
    }) => {
      const details = formatError(error)
      toast(
        isSuccess ? (
          <div className="flex flex-col gap-2 rounded bg-[rgba(24,207,106,0.2)] p-4">
            <Body2>{msg}</Body2>
            {txHash && (
              <Link
                href={`https://explorer.movementnetwork.xyz/txn/${txHash}?network=porto+testnet`}
                isExternal
                showAnchorIcon
                className="text-baseGrey"
              >
                <Body2>View on explorer</Body2>
              </Link>
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-2 rounded bg-[rgba(244,70,70,0.2)] p-4">
            <Body2>{msg}</Body2>
            {txHash && (
              <Link
                href={`https://explorer.movementnetwork.xyz/txn/${txHash}?network=porto+testnet`}
                isExternal
                showAnchorIcon
                className="text-baseGrey"
              >
                <Body2>View on explorer</Body2>
              </Link>
            )}
            {details && <Body2 className="text-baseGrey">{details}</Body2>}
          </div>
        ),
        {
          className: 'z-toast',
          bodyClassName: 'z-toast-body',
          progressClassName: isSuccess ? 'z-toast-progress-success' : 'z-toast-progress-failed',
          autoClose: 4000,
          pauseOnHover: isDesktop,
          position: 'top-right',
        },
      )
      setTimeout(() => {
        void refreshBalance()
      }, 4000)
    },
    [refreshBalance],
  )
}

export default function useSwapNotificationFn() {
  const dispatch = useAppDispatch()
  const { followingTokenData } = useTokenStore()
  const showToast = useShowToastWithExplorerLink()

  const sendNotification = useCallback(
    (
      tokenIn: string,
      tokenOut: string,
      amountIn: string,
      amountOut: string,
      txHash: string | undefined,
      isSuccess: boolean,
      error: any | undefined,
    ) => {
      const tokenInData = followingTokenData[tokenIn]
      const tokenOutData = followingTokenData[tokenOut]
      const details = formatError(error)
      if (tokenInData && tokenOutData) {
        const payload: ITransactionHistory = {
          txHash: txHash,
          isSuccess,
          details,
          tokenInSymbol: tokenInData.symbol,
          tokenOutSymbol: tokenOutData.symbol,
          readableAmountIn: divpowToFraction(amountIn, tokenInData.decimals).toSignificant(6),
          readableAmountOut: divpowToFraction(amountOut, tokenOutData.decimals).toSignificant(6),
          timestamp: Date.now(),
          tokenInAddress: tokenInData.id,
          tokenOutAddress: tokenOutData.id,
        }
        dispatch(addTransactionHistory(payload))
        showToast({
          isSuccess,
          txHash,
          msg: isSuccess
            ? `Swap ${payload.readableAmountIn} ${payload.tokenInSymbol} to ${payload.readableAmountOut} ${payload.tokenOutSymbol}`
            : `Error swapping ${payload.readableAmountIn} ${payload.tokenInSymbol} to ${payload.readableAmountOut} ${payload.tokenOutSymbol}`,
          error,
        })
      }
    },
    [dispatch, followingTokenData, showToast],
  )
  return sendNotification
}
