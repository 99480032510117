import { CloseIcon } from '@/components/Icons'
import { Subtitle1 } from '@/components/Typography'
import { useIsSm } from '@/hooks/useMedia'
import { Button, Modal, ModalContent, Spacer } from '@nextui-org/react'
import { ReactNode } from 'react'

export default function BaseModal({
  isOpen,
  children,
  className,
  onClose,
  title,
}: {
  isOpen: boolean
  children: ReactNode
  className?: string
  onClose: () => void
  title: string
}) {
  const isSm = useIsSm()

  return (
    <Modal
      isOpen={isOpen}
      placement={isSm ? 'bottom-center' : 'top-center'}
      backdrop="blur"
      hideCloseButton
      onClose={onClose}
      size={isSm ? 'full' : undefined}
    >
      <ModalContent
        className={`mt-[140px] max-w-[450px] rounded-lg bg-baseGrey1 text-foreground dark sm:mt-[unset] ${isSm ? 'h-fit max-h-[70vh] min-h-[200px] self-end' : ''} ${className}`}
      >
        <div className="flex items-center justify-between border-b-[0.5px] border-borderGrey px-5 py-3">
          <Subtitle1 className="text-baseGrey">{title}</Subtitle1>
          <Button isIconOnly variant="light" className="h-[20px] w-[20px] min-w-fit p-0" onPress={onClose}>
            <CloseIcon size={20} />
          </Button>
        </div>
        <Spacer y={4} />
        {children}
      </ModalContent>
    </Modal>
  )
}
