import { MosaicException, SwapMetadata } from '@mosaicag/swap-widget'
import { useCallback } from 'react'
import ReactGA from 'react-ga4'
import useSwapNotificationFn from './useSwapNotificationFn.tsx'

export default function useSwap() {
  const sendNotification = useSwapNotificationFn()

  const onSwap = useCallback(
    ({
      metadata: { tokenIn, tokenOut, amountIn, amountOut },
      success,
      hash,
      error,
    }: {
      metadata: SwapMetadata
      success: boolean
      hash: string
      error?: MosaicException
    }) => {
      if (success) {
        ReactGA.event({
          category: 'swap',
          action: 'swap_success',
          label: '',
          value: 1,
          nonInteraction: true,
          transport: 'beacon',
        })
        sendNotification(tokenIn?.id, tokenOut?.id, amountIn, amountOut, hash, true, '')
      } else {
        console.error('swap error', error)
        const errorDetails = error?.message || error?.code
        sendNotification(tokenIn?.id, tokenOut?.id, amountIn, amountOut, undefined, false, errorDetails)
        ReactGA.event({
          category: 'swap',
          action: 'swap_error',
          label: errorDetails,
          value: 0,
          nonInteraction: true,
          transport: 'beacon',
        })
      }
    },
    [sendNotification],
  )

  return { onSwap }
}
